import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { LoadingController, ModalController } from '@ionic/angular';
import 'firebase/analytics';
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from 'src/app/logger.service';
import { StorageService } from 'src/app/storage.service';
import { ChatService } from 'src/app/auth/login/chat.service'
import { AccountpopupComponent } from 'src/app/v2/components/accountpopup/accountpopup.component';
import { User } from './user.model';
import { SignupData } from 'src/app/v2/utils/export.service';
import { BillspopupComponent } from 'src/app/v2/components/billspopup/billspopup.component';
import { GenericpopupComponent } from 'src/app/v2/components/genericpopup/genericpopup.component';
import { DemopopupComponent } from 'src/app/v2/components/demopopup/demopopup.component';
import { TranslatorService } from 'src/app/translator.service';

export class TestStructure {
  public id: number;
  public label: string;
  public type: string;
  public uniqueID: string;
  public headerColor: string;
  public itemImage: string;
  public category: string;
  public startDate: number;
  public endDate: number;
  public result: string;
  public status: string;
  public price: string;
  public priceval: string;
  public pricedigital: string;
  public priority: string;
  public competitionData: {};
  public isTestVisible: boolean;
  public isCustomTest: boolean = false;
  public customData: {} = null;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _user = new BehaviorSubject<User>(null);
  public coordinatorCode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _activeUser: User;
  private userFirebase: firebase.User;
  private customExamID: Number = -1;
  public defaultOwnedItems: string = "1_1|2_1|3_1|4_1|5_1|6_1|7_1|8_1|9_1|10_1|11_1|12_1";
  public currentStructVersion: number = 0;
  public contestSaved: boolean = false;
  public userHasPaidJune: boolean = false;
  public loginData: string = "";
  public loginDataUrl: string = "";
  public paymentKey = "";
  public paymentData = "";
  public testCategories = [];
  public shopStructure = [];
  public examsList = [];
  public resultsStructure = [];
  public languagePath = "";
  public oneTimePopup = false;
  private testHeaderColor = ["success", "danger", "header1", "header2", "success", "danger", "header1", "header2",
    "success", "danger", "header1", "header2"];

  private _isUserPremium: boolean = true;
  private loadingAsset;
  ACTION_EDIT: number = 0;
  ACTION_NEW_FROM_TEACHER: number = 1;
  ACTION_TRANSITION_ACCOUNT: number = 2;
  ACTION_NEW_SIGNUP: number = 3;
  testsForTeacherPublic: any[];

  get isUserPremium() {
    return this._isUserPremium;
  }
  set isUserPremium(val: boolean) {
    this._isUserPremium = val;
  }
  get userIsAuthenticated() {
    this.logger.customLog("[login.service.ts] userIsAuthenticated()");
    let userData = firebase.auth().currentUser;
    return userData;
  }
  public isWsAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedAvatar = 0;

  public serverTime;
  public LOGIN_SUCCESS = 0;
  public LOGIN_INVALID = 1;
  public LOGIN_ERROR = 2;
  public SIGNUP_SUCCESS = 3;
  public SIGNUP_ERROR = 4;
  public SIGNUP_EXIST = 5;
  public ERROR = 6;
  public USER_EXIST = 7;
  public USER_NEW = 8;

  public userSnapshot;
  public counterOverlay = 0;

  public componentRef;
  public onStart: EventEmitter<any> = new EventEmitter<any>();

  public ownTeachersWithTests = [];
  public isCustomTestFeatureOn: boolean = false;
  public isARFeatureOn: boolean = false;

  public isUserIlona = false;

  get activeUserFirebase() { return this.userFirebase; }
  get activeUser() { return this._activeUser }
  constructor(private http: HttpClient, private router: Router, public translator: TranslatorService, private logger: LoggerService, private chatService: ChatService, private storage: StorageService, private modalCtrl: ModalController, public loadingController: LoadingController) {
    logger.customLog("[login.service.ts] constructor");
    let date = this.getServerTime();
    translator.setLoginService(this);
  }

  public setAppRef(ref) {
    this.componentRef = ref;
  }
  public async deletedata(path) {
    /*const loading = await this.loadingController.create({
      spinner: "crescent",
      message: 'Se încarcă datele!',
      translucent: true
    });
    await loading.present();*/
    this.showLoadingOverlay();
    return new Promise(resolve => {
      let localhostlink = 'https://europe-west1-formidabilii-a617f.cloudfunctions.net/deletedata?path=' + path;
      this.http
        .get(localhostlink)
        .subscribe(async (data: any) => {
          this.hideLoadingOverlay();
          // loading.dismiss();
          resolve(data);
        });
    });
  }

  public async pushmessage(path, message, username, name, avatarid) {
    /*const loading = await this.loadingController.create({
      spinner: "crescent",
      message: 'Se încarcă datele!',
      translucent: true
    });
    await loading.present();*/
    this.showLoadingOverlay();
    return new Promise(resolve => {
      //let debuglink = 'http://localhost:5000/formidabilii-a617f/europe-west1/pushmessage?path=' + path + '&message=' + message + '&username=' + username + '&name=' + name + '&avatarid=' + avatarid;

      let localhostlink = 'https://europe-west1-formidabilii-a617f.cloudfunctions.net/pushmessage?path=' + path + '&message=' + message + '&username=' + username + '&name=' + name + '&avatarid=' + avatarid;

      this.http
        .get(localhostlink)
        .subscribe(async (data: any) => {
          this.hideLoadingOverlay();
          // loading.dismiss();
          resolve(data);
        });
    });
  }
  public async checkCode(code) {
    /*const loading = await this.loadingController.create({
      spinner: "crescent",
      message: 'Se încarcă datele!',
      translucent: true
    });
    await loading.present();*/
    this.showLoadingOverlay();
    return new Promise(resolve => {
      let localhostlink = 'https://europe-west1-formidabilii-a617f.cloudfunctions.net/checkCode?code=' + code;
      this.http
        .get(localhostlink)
        .subscribe(async (data: any) => {
          this.hideLoadingOverlay();
          //loading.dismiss();
          resolve(data);
        });
    });
  }
  public async grabdata(path) {

    /*const loading = await this.loadingController.create({
      spinner: "crescent",
      message: 'Se încarcă datele!',
      translucent: true
    });
    await loading.present();*/
    this.showLoadingOverlay();
    return new Promise(resolve => {
      let localhostlink = 'https://europe-west1-formidabilii-a617f.cloudfunctions.net/retrievedata?path=' + path;
      this.http
        .get(localhostlink)
        .subscribe(async (data: any) => {
          this.hideLoadingOverlay();
          //loading.dismiss();
          resolve(data);
        });
    });
  }

  public async updatedata(path, vals) {
    /*const loading = await this.loadingController.create({
      spinner: "crescent",
      message: 'Se încarcă datele!',
      translucent: true
    });
    await loading.present();*/
    this.showLoadingOverlay();

    return new Promise(resolve => {
      let explodestring = "";
      for (var key in vals) {
        let item = key + "|_|" + vals[key];
        explodestring += item + "*_*";
      }
      let localhostlink = 'https://europe-west1-formidabilii-a617f.cloudfunctions.net/updatedata?path=' + path + "&vals=" + explodestring;
      this.http
        .get(localhostlink)
        .subscribe(async (data: any) => {
          this.hideLoadingOverlay();
          // loading.dismiss();
          resolve(data);
        });
    });
  }

  public showLoadingOverlay() {
    this.counterOverlay++;
    this.checkOverlay("add");

  }
  public async hideLoadingOverlay() {
    await this.delay(300);
    this.counterOverlay--;
    this.checkOverlay("substract");
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  private async checkOverlay(type: string) {
    let message = this.translator.lang['logindata'];
    if (this.counterOverlay === 1 && type === "add") {
      this.loadingAsset = await this.loadingController.create({
        spinner: "crescent",
        message: message,
        translucent: true
      });
      this.loadingAsset.present();
    }
    else if (this.counterOverlay === 0) {
      this.loadingAsset.dismiss();
    }
  }

  public async authenticate(user: firebase.User, idToken: string, fromEmail: boolean = false, forceGuest: boolean = false, customID = -1) {
    //this.logger.customLog("[login.service.ts] authenticate()" + user.isAnonymous);
    this.customExamID = customID;
    this.userFirebase = user;
    if (forceGuest) {
      firebase.analytics().logEvent('anonymoususer');
      this._activeUser = new User("", "", "", "", idToken, true, false, "", "", "", "", "", "", "", "", [], [], 0);
      //this.storeAuthData(this._activeUser);
      // TODO deschide popup pentru user anonim
      this.grabTestsAvailable();
      // this.router.navigateByUrl("/home/tabs/shop");
    }
    else {
      if (!this.isUserPremium) {
        this.modalCtrl.create({
          component: DemopopupComponent,
          componentProps:
          {
            loginService: this
          },
          cssClass: "modal-fullscreen",
          backdropDismiss: false,
          id: "popupdemo"
        }).then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss();
        }).then(resultData => {
          this.isUserPremium = true;
          this.authenticate(user, idToken)
        })
        return;
      }
      let path = '/v2/users/' + user.uid;
      await this.grabdata(path).then(async (data: any) => {
        if (data.data != 'fail') {
          //console.log(data);
          if (data.data === null) {
            //CHECK IF ACCOUNT TRANSITIONED
            let path = '/users/' + user.uid;
            await this.grabdata(path).then((data: any) => {
              //console.log(data);
              if (data.data != 'fail') {
                let oldVersion = data.data.email || "";
                let username = data.data.username || "";
                let pass = data.data.pass || "";
                if (oldVersion.length > 0 && username.length === 0) {
                  var email = data.data.email || '';
                  var name = data.data.name || 'Anonymous';
                  var phone = data.data.phone || '';
                  var isTeacher = data.data.isTeacher || '';
                  this.selectedAvatar = data.data.selectedAvatar || 0;

                  let configData: SignupData = new SignupData();
                  configData.username = "";
                  configData.email = email;
                  configData.pass = "";
                  configData.name = name;
                  configData.phone = phone;
                  configData.isTeacher = isTeacher;
                  configData.selectedAvatar = this.selectedAvatar;

                  this.modalCtrl.create({
                    component: AccountpopupComponent,
                    componentProps:
                    {
                      loginService: this,
                      configData: configData
                    },
                    cssClass: "modal-fullscreen",
                    backdropDismiss: false,
                    id: "accountpopupupdate"
                  }).then(modalEl => {
                    modalEl.present();
                    return modalEl.onDidDismiss();
                  }).then(resultData => {
                  })
                }
                else if (username.length > 0) {
                  if (fromEmail) {
                    alert("Pentru autentificare vă rugăm să folosiți numele de utilizator primit pe email în momentul în care v-ați actualizat contul!")
                  }
                  else {
                    this.loginWithUser(username, pass);
                  }
                }
              }
              else {
                alert("Am întâmpinat o eroare! Cod 0x1");
              }
            });
            return;
          }
          let judetId = data.data.judetId;
          let cityId = data.data.cityId;
          let unitateId = data.data.unitateId;
          let judetLabel = data.data.judetLabel;
          let cityLabel = data.data.cityLabel;
          let unitateLabel = data.data.unitateLabel;
          let accountType = data.data.accountType;
          let path2: string = 'v2/judete/' + judetId + "/localitati/" + cityId + "/unitati/" + unitateId + "/" + accountType + "/" + user.uid;
          await this.grabdata(path2).then(async (data: any) => {
            if (data != 'fail') {
              this.userSnapshot = data.data;
              let email = data.data.email;
              let name = data.data.name;
              let phone = data.data.phone;
              let isTeacher = accountType === "teachers";
              let isAnonymous: boolean = false;
              let clasaId = data.data.clasaId;
              let clasaLabel = data.data.clasaLabel;
              let clasaNameId = data.data.clasaNameId;
              let clasaNameLabel = data.data.clasaNameLabel;
              let unitID = judetId + "_" + cityId + "_" + unitateId;
              let teachers = [];
              let teachersUID = [];
              let selectedAvatar = data.data.selectedAvatar;
              if (user.uid === "testseb2" || user.uid === "testseb1") {
                //this.isCustomTestFeatureOn = true;
                //this.isARFeatureOn = true;
              }
              if (this.isCustomTestFeatureOn != false) {
                if (!isTeacher) {

                  let path: string = 'v2/judete/' + judetId + "/localitati/" + cityId + "/unitati/" + unitateId + "/" + accountType + "/" + user.uid + "/teachers";
                  await this.grabdata(path).then((data: any) => {
                    if (data != 'fail') {
                      for (let i in data.data) {
                        teachers.push(data.data[i].name)
                        teachersUID.push(i);
                      }
                    }
                    else {
                      alert("Am întâmpinat o eroare! Cod 0x2");
                    }
                  });
                  let teachersPath = 'v2/judete/' + judetId + "/localitati/" + cityId + "/unitati/" + unitateId + "/teachers/"
                  await this.grabOwnTeachersTests(teachersPath, teachers, teachersUID);
                  //console.log(this.ownTeachersWithTests);
                }
                else {
                  let teachersPath = 'v2/judete/' + judetId + "/localitati/" + cityId + "/unitati/" + unitateId + "/teachers/";
                  await this.grabOwnTeachersTests(teachersPath, [name], [user.uid]);
                }
              }

              this._activeUser = new User(user.uid, email, name, phone, idToken, isAnonymous, isTeacher, clasaId, clasaLabel, clasaNameId, clasaNameLabel, unitID, judetLabel, cityLabel, unitateLabel, teachers, teachersUID, selectedAvatar);
              await this.grabTestsAvailable();
            }
            else {
              alert("Am întâmpinat o eroare! Cod 0x3");
            }
          });
        }
        else {
          alert("Am întâmpinat o eroare! Cod 0x4");
        }
      });
    }
    return true;
  }

  public async grabOwnTeachersTests(path, teachers, teachersUID) {
    for (let i = 0; i < teachers.length; i++) {
      await this.grabdata(path + teachersUID[i] + "/tests/data").then(async (data: any) => {
        if (data.data != 'fail') {
          if (data.data != undefined && data.data.counter >= 0) {
            this.ownTeachersWithTests.push({ uid: teachersUID[i], teacherName: teachers[i], testCount: data.data.counter, titles: data.data.titles, clasa: data.data.clasa })
          }
        }

      });
    }
  }
  public async grabTestsAvailable() {
    let path: string = 'v2/categories/';
    console.log("grabTests!" + path)
    this.grabdata(path).then(async (data: any) => {
      console.log(data);
      if (data.data != 'fail' && data.data != null) {
        this.testCategories = [];
        this.shopStructure = [];
        this.resultsStructure = [];
        let localTestCategories = [];
        let localShopStructure = [];
        let localResultsStructure = [];
        let localTestHeaderColor = this.testHeaderColor;
        let localUserSnapshot = this.userSnapshot;
        let localExamsList = [];
        for (var key in data.data) {

          var childData = data.data[key];
          localTestCategories.push(childData.label);
          localShopStructure.push([]);
          localResultsStructure.push([]);
          for (let j = 0; j < childData.tests.length; j++) {
            let test: TestStructure = new TestStructure();
            test.id = childData.tests[j].id;
            test.label = childData.tests[j].label;
            test.type = childData.tests[j].type;
            test.priority = childData.tests[j].priority;
            test.uniqueID = childData.tests[j].uniqueID;
            test.headerColor = localTestHeaderColor[key];
            test.itemImage = "./../../../assets/v2/exams/thumbnails/" + key + "_" + j + ".png";
            test.category = childData.label;
            test.startDate = +childData.tests[j].startDate;
            test.price = childData.tests[j].price || "";
            test.priceval = childData.tests[j].priceval || "";
            test.pricedigital = childData.tests[j].pricedigital || "";
            test.isTestVisible = childData.tests[j].isTestVisible != undefined && childData.tests[j].isTestVisible != 'undefined' ? childData.tests[j].isTestVisible : true;
            test.status = childData.tests[j].status;
            if (this.activeUser.uid === "testseb1") {
              test.isTestVisible = true;
            }


            if (this.activeUser.isAnonymous) {
              test.price = test.priceval;
            }
            if (!this.activeUser.isTeacher) {
              test.price = test.priceval;
            }
            if (test.type === 'competition' || test.type === 'extra') {
              let bronzeMedalPath: string = childData.tests[j].bronzeMedalPath;
              let silverMedalPath: string = childData.tests[j].silverMedalPath;
              let goldMedalPath: string = childData.tests[j].goldMedalPath;
              let cupPath: string = childData.tests[j].cupPath;
              let diplomaPath: string = childData.tests[j].diplomaPath;
              localExamsList.push(test.uniqueID);
              test.competitionData = { bronzeMedalPath: bronzeMedalPath, silverMedalPath: silverMedalPath, goldMedalPath: goldMedalPath, cupPath: cupPath, diplomaPath: diplomaPath };
            }
            let result = "";

            if (localUserSnapshot && localUserSnapshot.tests && localUserSnapshot.tests[key] &&
              localUserSnapshot.tests[key][j] &&
              localUserSnapshot.tests[key][j].val) {
              result = localUserSnapshot.tests[key][j].val;
            }
            test.result = result;
            let status = childData.tests[j].status;
            let action = "";
            if (localUserSnapshot && localUserSnapshot.tests && localUserSnapshot.tests[key] &&
              localUserSnapshot.tests[key][j]) {
              status = localUserSnapshot.tests[key][j].status != "undefined" ? localUserSnapshot.tests[key][j].status : "";
              action = localUserSnapshot.tests[key][j].action != "undefined" ? localUserSnapshot.tests[key][j].action : "";
              if (action === "confirmed") {
                status = "confirmed";
              }
            }
            test.status = status;

            let endDate = 0;
            if (localUserSnapshot && localUserSnapshot.tests && localUserSnapshot.tests[key] &&
              localUserSnapshot.tests[key][j] &&
              localUserSnapshot.tests[key][j].endDate) {
              endDate = +localUserSnapshot.tests[key][j].endDate;
            }
            test.endDate = endDate;
            localShopStructure[key].push(test);
          }
          this.testCategories = localTestCategories;
          this.shopStructure = localShopStructure;
          this.resultsStructure = localResultsStructure;
          this.examsList = localExamsList;
          //this.refreshTestsResults(this.activeUser.userUnitID, this.activeUser.uid);
        }
        if (this.activeUser.isTeacher) {
          await this.findValidCompetition();
        }

        this.router.navigateByUrl('/home/tabs/shop' + "?isCustomExamID=" + this.customExamID);


        //alert("Versiunea online a concursul lunii martie se va desfășura începând cu data de 13 martie 2021, pentru permiterea susținerii concursului în format fizic în prima săptămână a acestei luni. Concursul va rămâne activ până la data de 31 martie inclusiv. Pentru informații suplimentare, nu ezitați să ne contactați la adresa de email office@romconcept.ro")
      }
      else {
        //alert("Am întâmpinat o eroare! Cod 0x5");
      }
    });
  }

  public async grabCustomTests() {
    return new Promise(async resolve => {
      let path = "v2/customtests/" + this.languagePath + "struct/";
      await this.grabdata(path).then(async (data: any) => {
        if (data.data != 'fail' && data.data != null) {
          let customTests = [];
          for (let i = 0; i < data.data.length; i++) {
            let test: TestStructure = new TestStructure();
            test.id = i;
            test.label = data.data[i].title;
            test.type = "free";
            test.priority = "2";
            test.uniqueID = "" + i;
            test.headerColor = "header2";
            test.itemImage = "./../../../assets/v2/exams/thumbnails/testdemo.png";
            test.category = this.logger.dataClass[data.data[i].clasa].name;
            test.isCustomTest = true;
            test.isTestVisible = data.data[i].status === "true" ? true : false;
            test.customData = { name: data.data[i].name, city: data.data[i].localitate };
            customTests.push(test);
          }
          this.testsForTeacherPublic = customTests;
          console.log(this.testsForTeacherPublic);
          resolve(customTests);
        }
        else {
          this.testsForTeacherPublic = [];
        }
      });
    })
  }

  public async findValidCompetition() {
    let unit = this.activeUser.userUnitID.split("_");
    let path = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/teachers/" + this.activeUser.uid + "/bills/";
    let data = await this.getBillsForActiveCompetition(path);
    let activeExams = [];
    for (var key in data.data) {
      let innerPath = 'v2/bills/' + key + "_" + this.activeUser.uid;
      let innerData = await this.getBillsByID(innerPath);
      if (innerData.data.status === "confirmed") {
        let exams = innerData.data.examsPurchasedList.split("|");
        for (let l = 0; l < exams.length; l++) {
          if (activeExams.indexOf(exams[l]) === -1) {
            activeExams.push(exams[l]);
          }
        }
      }
    }
    for (let i = 0; i < activeExams.length; i++) {
      let val = activeExams[i].split("_");
      this.shopStructure[val[0]][val[1]].status = "confirmed";
    }
  }

  public async refreshTestsResults(userUnitID: string, uid) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + uid + "/tests/";
    this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        let localResultsStructure = this.resultsStructure;
        for (var key in data.data) {
          let idx = key;
          for (var internalkey in data.data[key]) {
            let itemIdx = internalkey;
            let result = data.data[key][internalkey].val || "";
            let status = data.data[key][internalkey].status || "";
            localResultsStructure[idx][itemIdx] = { result: result, status: status }
            return localResultsStructure;
          }
        }
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x6");
      }
    });
  }

  public getLinkToUpload(testResult, classId, groupId) {
    let shopItemData = this.shopStructure[classId][groupId];

    let val = testResult;
    let labelPoints;
    let diplomaPath = shopItemData.competitionData.diplomaPath;
    let medalPath = "";
    let cupPath = "";
    if (val === 100) {
      cupPath = shopItemData.competitionData.cupPath;
    }
    if (val >= 90) {
      labelPoints = "Premiul I - " + val + ' de puncte';
      medalPath = shopItemData.competitionData.goldMedalPath;
    }
    else if (val >= 80 && val < 90) {
      labelPoints = "Premiul al II- lea - " + val + ' de puncte';
      medalPath = shopItemData.competitionData.silverMedalPath;
    }
    else if (val >= 70 && val < 80) {
      labelPoints = "Premiul al III-lea - " + val + ' de puncte';
      medalPath = shopItemData.competitionData.bronzeMedalPath;
    }
    else if (val >= 60 && val < 50) {
      labelPoints = "Mențiune - " + val + ' de puncte';
    }
    else if (val >= 50 && val <= 20) {
      labelPoints = "Diplomă de participare - " + val + ' de puncte';
    }
    else {
      labelPoints = "Diplomă de participare - " + val + ' puncte';
    }

    let teachers = "";
    for (let i = 0; i < this.activeUser.teachers.length; i++) {
      teachers += this.activeUser.teachers[i] + " ";
    }

    let liveLink = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/uploadImage';
    let linkDebug = liveLink + '?username=' + this.activeUser.uid + '&examID='
      + classId + '_' + groupId
      + '&diplomaPoints=' + labelPoints + '&awardName=' + this.activeUser.name + '&diplomaClass='
      + this.activeUser.clasaLabel + ' ' + this.activeUser.clasaNameLabel
      + '&diplomaUnit=' + this.activeUser.unitateLabel
      + '&diplomaCity=' + this.activeUser.cityLabel + '&diplomaCounty=' + this.activeUser.judetLabel + '&diplomaTeacher=' + teachers;
    let linkData = { linkDebug: linkDebug, medalPath: medalPath, diplomaPath: diplomaPath, cupPath: cupPath }
    return linkData;
  }

  public generateBill(billdata) {

    let localStartDate = new Date(billdata.startDate).toLocaleDateString('ro-RO', { timeZone: 'EST' });
    let localEndDate = new Date(billdata.endDate).toLocaleDateString('ro-RO', { timeZone: 'EST' });
    let localhostlink = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/generateBill?';
    let data = 'billNumber=' + billdata.billNumber + '&startDate=' + localStartDate + '&endDate=' + localEndDate +
      '&clientName=' + billdata.clientName + '&address=' + billdata.address + '&judet=' + billdata.judet +
      '&product=' + billdata.product + '&quantity=' + billdata.quantity + '&priceperunit=' + billdata.priceperunit + '&pricepertotal=' + billdata.pricepertotal +
      '&taxpertotal=' + billdata.taxpertotal + '&discountpertotal=' + billdata.discountpertotal + '&discountpertotalval=' + billdata.discountpertotalval +
      '&discountpertotaltax=' + billdata.discountpertotaltax + '&totalafterdiscount=' + billdata.totalafterdiscount + '&totaltaxafterdiscount=' + billdata.totaltaxafterdiscount +
      '&totalwithtax=' + billdata.totalwithtax + '&assetpath=' + billdata.assetpath + '&username=' + billdata.username;
    localhostlink = localhostlink + data;
    let yourheaders = new HttpHeaders({
      "Content-Type": 'application/x-www-form-urlencoded'
    });
    //let options = new RequestOptions({ headers: headers });
    yourheaders.append("Content-Type", 'application/x-www-form-urlencoded');
    yourheaders.append('Access-Control-Allow-Origin', '*');
    yourheaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    yourheaders.append('Accept', 'application/json');
    yourheaders.append('content-type', 'application/json');

    //let localLync = linkDebug + '&assetPath=' + path + '&typeprize=' + type;

    let youroptions = {
      headers: yourheaders
    }
    this.http
      .get(localhostlink, youroptions)
      .subscribe((data: any) => {
        if (data != "false") {
          this.saveBillData(billdata.billNumber, billdata.username, billdata.students,
            billdata.endDate, billdata.productID, billdata.pdfPath,
            billdata.totalwithtax, billdata.examsPurchased, billdata.product, billdata.agent, billdata.paymentType);
        }
      });
  }

  public async getBillsForActiveCompetition(path) {
    let billsObject;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        billsObject = data;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x6");
      }
    });
    //console.log(billsObject)
    return billsObject;
  }
  public async getBillsByID(path) {
    let billsObject;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        billsObject = data;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x6");
      }
    });
    //console.log(billsObject)
    return billsObject;
  }
  public async openBillsPopup(ref, path) {
    let billsObject = [];
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        for (var key in data.data) {
          let childSnapshot = data.data[key];
          billsObject.push({ 'path': childSnapshot.path, 'startDate': +childSnapshot.startDate, 'endDate': +childSnapshot.endDate })
        }
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x6");
      }
    });

    ref.modalCtrl.create({
      component: BillspopupComponent,
      cssClass: "modal-fullscreen",
      componentProps:
      {
        billsObject: billsObject
      },
      id: "billPopup"
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    }).then(async resultData => { });
  }
  public resetStudentPayment(uid, userUnitID, classId, examId) {
    let unit = userUnitID.split("_");
    let path = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + uid + "/tests/" + classId + "/" + examId;
    let vals = {};
    vals['status'] = "";
    vals['owner'] = "";
    vals['endDate'] = "";
    vals['billID'] = "";
    this.updatedata(path, vals);
  }
  public async getServerTime(): Promise<number> {
    let path = "v2/isCustomTestFeatureOn/"
    let isCustomFeature = false;
    await this.grabdata(path).then((data: any) => {
      if (data != 'fail') {
        if (data.data != null && data.data.status != null) {
          isCustomFeature = data.data.status;
        }
      }
      else {
      }
    });
    this.isCustomTestFeatureOn = isCustomFeature;

    //http://localhost:5001/formidabilii-a617f/europe-west3/sendmail.
    let debugLink = "http://localhost:5001/formidabilii-a617f/europe-west3/getServerTime"
    let localhostlink = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/getServerTime';
    let yourheaders = new HttpHeaders({
      "Content-Type": 'application/x-www-form-urlencoded'
    });
    yourheaders.append("Content-Type", 'application/x-www-form-urlencoded');
    yourheaders.append('Access-Control-Allow-Origin', '*');
    yourheaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    yourheaders.append('Accept', 'application/json');
    yourheaders.append('content-type', 'application/json');
    let youroptions = {
      headers: yourheaders
    }
    const response = await this.http
      .get(localhostlink, youroptions).toPromise();
    /* .subscribe((data: any) => {
       this.serverTime = data.data + 10860;
       return this.serverTime;
     });*/
    this.serverTime = response['data'];

    return this.serverTime;
  }
  //save exam data for user and declare owner of the purchase the current merchant
  public async initPaymentToDB(idx, itemIdx, username, endDate, currentBillID) {
    let unit = this.activeUser.userUnitID.split("_");
    let path = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + username + "/tests/" + idx + "/" + itemIdx;
    let vals = {};
    vals['label'] = this.shopStructure[idx][itemIdx].label;
    vals['category'] = this.shopStructure[idx][itemIdx].category;
    vals['status'] = 'pending';
    vals['owner'] = this.activeUser.uid;
    vals['billID'] = currentBillID;
    vals['endDate'] = endDate;
    await this.updatedata(path, vals);
  }

  public forcePaymentToDB(idx, itemIdx, username, endDate, currentBillID) {
    let unit = this.activeUser.userUnitID.split("_");
    let path = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + username + "/tests/" + idx + "/" + itemIdx;
    let vals = {};
    vals['label'] = this.shopStructure[idx][itemIdx].label;
    vals['category'] = this.shopStructure[idx][itemIdx].category;
    vals['status'] = 'confirmed';
    vals['owner'] = this.activeUser.uid;
    vals['endDate'] = endDate;
    vals['billID'] = currentBillID;
    this.updatedata(path, vals);
  }

  public async getCurrentBill() {
    let path = 'v2/bills/counter';
    let currentBillID = 0;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        currentBillID = +data.data;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x7");
      }
    });
    return currentBillID;
  }

  public updateCurrentBill(currentBillID) {
    let path = 'v2/bills';
    let vals = {};
    vals["counter"] = currentBillID;
    this.updatedata(path, vals);
  }
  public async saveBillData(billNumber, username, students, endDate, productID, pdfPath, total, examsPurchased, examLabel, agent, paymentType) {
    let path = 'v2/bills/' + billNumber + "_" + username;
    let studentsList = students.join("_");
    let examsPurchasedList = examsPurchased.join("|");
    let localRef = this;
    let localUser = this._activeUser;
    let localServerTime = this.serverTime;

    let vals = {};
    vals['students'] = studentsList;
    vals['expiryDate'] = endDate;
    vals['productID'] = productID;
    vals['examLabel'] = examLabel;
    vals['pdfPath'] = pdfPath;
    vals['status'] = "pending";
    vals['total'] = total;
    vals['teacherName'] = this.activeUser.name;
    vals['schoolName'] = this.activeUser.unitateLabel;
    vals['countyName'] = this.activeUser.judetLabel;
    vals['cityName'] = this.activeUser.cityLabel;
    vals['phone'] = this.activeUser.phone;
    vals['userUnitID'] = this.activeUser.userUnitID;
    vals['examsPurchasedList'] = examsPurchasedList;
    vals['agent'] = agent;

    this.updatedata(path, vals).then((data: any) => {
      if (data.data === 'success') {
        let unit = localUser.userUnitID.split("_");
        let path2 = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/teachers/" + username + "/bills/";
        let internalvals = {};
        internalvals['path'] = pdfPath;
        internalvals['endDate'] = endDate;
        internalvals['startDate'] = localServerTime;
        this.updatedata(path2 + billNumber, internalvals).then((data: any) => {
          if (paymentType != "paynow") {
            localRef.openBillsPopup(localRef, path2);
          }
        });
      }
      else { alert("Am întâmpinat o eroare! Cod 0x8"); }
    });
  }

  public async getAllBills() {
    let path = 'v2/bills/';
    let bills = [];

    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        for (var key in data.data) {
          if (key != 'counter' && data.data[key].agent != undefined) {
            let childSnapshot = data.data[key];
            let date = new Date(+childSnapshot.expiryDate).toLocaleDateString('ro-RO', { timeZone: 'EST' });
            let hours = new Date(+childSnapshot.expiryDate).toLocaleTimeString('ro-RO');
            let students = childSnapshot.students.split("_");
            let examsPurchased = childSnapshot.examsPurchasedList.split("|");
            bills.push({
              billKey: key,
              product: childSnapshot.examLabel,
              expiryDate: date,
              expiryHour: hours,
              pdfPath: childSnapshot.pdfPath,
              productID: childSnapshot.productID,
              status: childSnapshot.status,
              students: students,
              examsPurchasedList: examsPurchased,
              total: childSnapshot.total,
              teacherName: childSnapshot.teacherName,
              schoolName: childSnapshot.schoolName,
              countyName: childSnapshot.countyName,
              cityName: childSnapshot.cityName,
              phone: childSnapshot.phone,
              userUnitID: childSnapshot.userUnitID,
              agent: childSnapshot.agent
            })
          }
        }
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x9");
      }
    });
    return bills;
  }

  public validatePayment(userUnitID, students, examsPurchased, billKey) {
    let unit = userUnitID.split("_");
    for (let i = 0; i < students.length; i++) {
      let internal = examsPurchased[i].split("_");
      let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + students[i] + "/tests/" + internal[0] + "/" + internal[1];

      let vals = {};
      vals['status'] = 'confirmed';
      this.updatedata(path, vals);
    }
    let path = 'v2/bills/' + billKey;
    let vals = {};
    vals['status'] = 'confirmed';
    this.updatedata(path, vals);
  }

  public async grabRecords(resultData) {
    let unitList = [];
    if (resultData.unitate === undefined && resultData.localitate === undefined && resultData.judet === undefined) {
      let data = await this.getRecordsForAll(resultData);
      unitList = data;
    }
    else if (resultData.unitate === undefined && resultData.localitate === undefined) {
      let data = await this.getRecordsForCounty(resultData);
      unitList = data;
    }
    else if (resultData.unitate === undefined) {
      let data = await this.getRecordsForCity(resultData);
      unitList = data;
    }
    else {
      let data = await this.getRecordsForUnit(resultData);
      unitList = data;
    }
    return unitList;
  }

  private async getRecordsForAll(resultData) {
    let path: string = 'v2/judete/';
    let localExamsList = this.examsList;
    let unitList = [];

    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        for (var key in data.data) {
          let childSnapshot = data.data[key];
          resultData.judetLabel = childSnapshot.name;
          let localitati = childSnapshot.localitati;
          if (localitati != undefined) {
            for (let n = 0; n < localitati.length; n++) {
              resultData.localitateLabel = localitati[n].name;
              let unitati = localitati[n].unitati;
              for (let i = 0; i < unitati.length; i++) {
                resultData.unitateLabel = unitati[i].name;
                let students = unitati[i].students;
                let studentsList = [];
                let confirmed = 0;
                let confirmedIndividual = 0;
                let pending = 0;
                let expired = 0;
                if (students != undefined) {
                  for (var key in students) {
                    let tests = students[key].tests;
                    for (let j = 0; j < localExamsList.length; j++) {
                      let internal = localExamsList[j].split("_");
                      if (tests) {
                        if (tests[internal[0]] != undefined && tests[internal[0]][internal[1]] != undefined) {
                          let activeCompetition = tests[internal[0]][internal[1]];
                          let teacherName = "";
                          if (activeCompetition.owner != "" && key != activeCompetition.owner) {
                            teacherName = unitati[i].teachers[activeCompetition.owner].name;
                          }
                          switch (activeCompetition.status) {
                            case "confirmed":
                              if (teacherName === '') {
                                confirmedIndividual++;
                              }
                              else {
                                confirmed++;
                              }
                              break;
                            case "pending":
                              pending++;
                              break;
                            case "expired":
                              expired++;
                              break;
                          }
                          if (activeCompetition.status != "") {
                            studentsList.push({ teacherName: teacherName, name: students[key].name, user: key, owner: activeCompetition.owner, label: activeCompetition.label, category: activeCompetition.category, status: activeCompetition.status, val: activeCompetition.val });
                          }
                        }
                      }
                    }
                  }
                  unitList.push({
                    judet: resultData.judetLabel, localitate: resultData.localitateLabel, unitate: resultData.unitateLabel,
                    studentsList: studentsList, confirmed: confirmed, pending: pending, expired: expired, confirmedIndividual: confirmedIndividual
                  });
                }
              }
            }
          }
        }
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x10");
      }
    });
    return unitList;
  }

  private async getRecordsForCounty(resultData) {

    let path: string = 'v2/judete/' + resultData.judet + "/localitati/";
    let localExamsList = this.examsList;
    let unitList = [];

    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        for (var key in data.data) {
          let childSnapshot = data.data[key];
          resultData.localitateLabel = childSnapshot.name;
          let unitati = childSnapshot.unitati;
          for (let i = 0; i < unitati.length; i++) {
            resultData.unitateLabel = unitati[i].name;
            let students = unitati[i].students;
            let studentsList = [];
            let confirmed = 0;
            let pending = 0;
            let expired = 0;
            let confirmedIndividual = 0;
            if (students != undefined) {
              for (var key in students) {
                let tests = students[key].tests;
                for (let j = 0; j < localExamsList.length; j++) {
                  let internal = localExamsList[j].split("_");
                  if (tests) {
                    if (tests[internal[0]] != undefined && tests[internal[0]][internal[1]] != undefined) {
                      let activeCompetition = tests[internal[0]][internal[1]];
                      let teacherName = "";
                      if (activeCompetition.owner != "" && key != activeCompetition.owner) {
                        teacherName = unitati[i].teachers[activeCompetition.owner].name;
                      }
                      switch (activeCompetition.status) {
                        case "confirmed":
                          if (teacherName === '') {
                            confirmedIndividual++;
                          }
                          else {
                            confirmed++;
                          }
                          break;
                        case "pending":
                          pending++;
                          break;
                        case "expired":
                          expired++;
                          break;
                      }
                      if (activeCompetition.status != "") {
                        studentsList.push({ teacherName: teacherName, name: students[key].name, user: key, owner: activeCompetition.owner, label: activeCompetition.label, category: activeCompetition.category, status: activeCompetition.status, val: activeCompetition.val });
                      }
                    }
                  }
                }
              }
              //unitList.push({ judet: resultData.judetLabel, localitate: resultData.localitateLabel, unitate: resultData.unitateLabel, studentsList: studentsList });
              unitList.push({
                judet: resultData.judetLabel, localitate: resultData.localitateLabel, unitate: resultData.unitateLabel,
                studentsList: studentsList, confirmed: confirmed, pending: pending, expired: expired, confirmedIndividual: confirmedIndividual
              });
            }
          }
        }
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x11");
      }
    });
    return unitList;
  }
  private async getRecordsForCity(resultData) {
    let path: string = 'v2/judete/' + resultData.judet + "/localitati/" + resultData.localitate + "/unitati/";
    let localExamsList = this.examsList;
    let unitList = [];
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        for (var key in data.data) {
          let childSnapshot = data.data[key];
          resultData.unitateLabel = childSnapshot.name;
          let students = childSnapshot.students;
          let studentsList = [];
          let confirmed = 0;
          let confirmedIndividual = 0;
          let pending = 0;
          let expired = 0;
          if (students != undefined) {
            for (var key in students) {
              let tests = students[key].tests;
              for (let j = 0; j < localExamsList.length; j++) {
                let internal = localExamsList[j].split("_");
                if (tests) {
                  if (tests[internal[0]] != undefined && tests[internal[0]][internal[1]] != undefined) {
                    let activeCompetition = tests[internal[0]][internal[1]];
                    let teacherName = "";
                    if (activeCompetition.owner != "" && key != activeCompetition.owner) {
                      teacherName = childSnapshot.teachers[activeCompetition.owner].name;
                    }
                    switch (activeCompetition.status) {
                      case "confirmed":
                        if (teacherName === '') {
                          confirmedIndividual++;
                        }
                        else {
                          confirmed++;
                        }
                        break;
                      case "pending":
                        pending++;
                        break;
                      case "expired":
                        expired++;
                        break;
                    }
                    if (activeCompetition.status != "") {
                      studentsList.push({ teacherName: teacherName, name: students[key].name, user: key, owner: activeCompetition.owner, label: activeCompetition.label, category: activeCompetition.category, status: activeCompetition.status, val: activeCompetition.val });
                    }
                  }
                }
              }
            }
            unitList.push({
              judet: resultData.judetLabel, localitate: resultData.localitateLabel, unitate: resultData.unitateLabel, studentsList: studentsList,
              confirmed: confirmed, pending: pending, expired: expired, confirmedIndividual: confirmedIndividual
            });
          }
        }

      }
      else {
        alert("Am întâmpinat o eroare!")
      }
    });
    return unitList;
  }

  private async getRecordsForUnit(resultData) {
    let path: string = 'v2/judete/' + resultData.judet + "/localitati/" + resultData.localitate + "/unitati/" + resultData.unitate;
    let unitList = [];
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        const snapshot = data.data;
        let students = snapshot.students;
        let studentsList = [];

        let confirmed = 0;
        let pending = 0;
        let expired = 0;
        let confirmedIndividual = 0;
        if (students != undefined) {
          for (var key in students) {
            let tests = students[key].tests;
            for (let j = 0; j < this.examsList.length; j++) {
              let internal = this.examsList[j].split("_");
              if (tests) {
                if (tests[internal[0]] != undefined && tests[internal[0]][internal[1]] != undefined) {
                  let activeCompetition = tests[internal[0]][internal[1]];
                  let teacherName = "";
                  if (activeCompetition.owner != "" && key != activeCompetition.owner) {
                    teacherName = snapshot.teachers[activeCompetition.owner].name;
                  }
                  switch (activeCompetition.status) {
                    case "confirmed":
                      if (teacherName === '') {
                        confirmedIndividual++;
                      }
                      else {
                        confirmed++;
                      }
                      break;
                    case "pending":
                      pending++;
                      break;
                    case "expired":
                      expired++;
                      break;
                  }
                  if (activeCompetition.status != "") {
                    studentsList.push({ teacherName: teacherName, name: students[key].name, user: key, owner: activeCompetition.owner, label: activeCompetition.label, category: activeCompetition.category, status: activeCompetition.status, val: activeCompetition.val });
                  }
                  /*unitList.push({
                    judet: resultData.judetLabel, localitate: resultData.localitateLabel, unitate: resultData.unitateLabel,
                    studentsList: studentsList, confirmed: confirmed, pending: pending, expired: expired, confirmedIndividual: confirmedIndividual
                  });*/
                }
              }
            }
          }
          unitList.push({
            judet: resultData.judetLabel, localitate: resultData.localitateLabel, unitate: resultData.unitateLabel, studentsList: studentsList,
            confirmed: confirmed, pending: pending, expired: expired, confirmedIndividual: confirmedIndividual
          });
        }

      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x12");
      }
    });
    return unitList;
  }
  public async getExamIdForStudent(studentID, userUnitID, examLabel) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + studentID + "/clasaId";
    let studentClassId = -1;
    let examIdx = -1;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        const snapshot = data.data;
        studentClassId = snapshot;
        if (studentClassId != null) {
          for (let i = 0; i < this.shopStructure[studentClassId].length; i++) {
            if (this.shopStructure[studentClassId][i].label === examLabel) {
              examIdx = i;
              break;
            }
          }
        }
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x13");
      }
    });
    return { idx: studentClassId, itemIdx: examIdx, userid: studentID };
  }

  //generates cup/medal/diploma based on results
  public generateAssets(linkDebug, type, path) {
    let yourheaders = new HttpHeaders({
      "Content-Type": 'application/x-www-form-urlencoded'
    });
    //let options = new RequestOptions({ headers: headers });
    yourheaders.append("Content-Type", 'application/x-www-form-urlencoded');
    yourheaders.append('Access-Control-Allow-Origin', '*');
    yourheaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    yourheaders.append('Accept', 'application/json');
    yourheaders.append('content-type', 'application/json');

    let localLync = linkDebug + '&assetPath=' + path + '&typeprize=' + type;

    let youroptions = {
      headers: yourheaders
    }
    this.http
      .get(localLync, youroptions)
      .subscribe((data: any) => {
      });
  }

  public isTokenValid(accountid: string, token: string, startdate: number) {
    //http://localhost:5000/formidabilii-a617f/us-central1/findaccount
    let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/istokenvalid?type=istokenvalid&accountid=' + accountid + "&token=" + token + "&startdate=" + startdate;
    this.http
      .get(linkTest)
      .subscribe((data: any) => {
        return data;
      });
  }

  public updatePassword(accountid: string, password: string, token: string) {
    //http://localhost:5000/formidabilii-a617f/us-central1/findaccount
    this.showLoadingOverlay();
    return new Promise(resolve => {
      let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/updatepassword?type=updatepassword&accountid=' + accountid + "&password=" + password + "&token=" + token;
      this.http
        .get(linkTest)
        .subscribe(async (data: any) => {
          this.hideLoadingOverlay();
          // loading.dismiss();
          resolve(data);
        });
    });
  }

  public findAccount(accountid: string) {
    //http://localhost:5000/formidabilii-a617f/us-central1/findaccount
    let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/findaccount?type=recover&accountid=' + accountid;
    this.http
      .get(linkTest)
      .subscribe((data: any) => {
        var emailAddress = data.data;
        let bodyMessage = "Vom trimite un email la adresa: " + emailAddress;
        let buttons = [{ label: "Recuperare parolă", action: 0 }];
        this.modalCtrl.create({ component: GenericpopupComponent, componentProps: { title: "Recuperare parolă", body: bodyMessage, buttons: buttons }, backdropDismiss: true }).then(modalEl => {
          modalEl.present();
          modalEl.onDidDismiss().then(result => {
            if (result.data && result.data.action === 0) {
              this.sendRecoverPasswordEmail(accountid, emailAddress);
            }
            /*
            var auth = firebase.auth();
            auth.sendPasswordResetEmail(emailAddress).then(function () {
              // Email sent.
            }).catch(function (error) {
              // An error happened.
            });
            */
          })
        })
      });
  }

  public openGenericPopup(title, bodyMessage, buttons) {
    this.modalCtrl.create({ component: GenericpopupComponent, componentProps: { title: title, body: bodyMessage, buttons: buttons }, backdropDismiss: true }).then(modalEl => {
      modalEl.present();
      modalEl.onDidDismiss().then(result => {
        if (result.data && result.data.action === 0) {

        }
      })
    });
  }
  public sendRecoverPasswordEmail(accountid, emailAddress) {
    //http://localhost:5000/formidabilii-a617f/us-central1/sendpasswordemail
    let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/sendpasswordemail?accountid=' + accountid + '&email=' + emailAddress;
    this.http
      .get(linkTest)
      .subscribe((data: any) => {

      });
  }
  public signup(data: SignupData) {
    let localData: SignupData = data;
    let ref = this;
    if (this.activeUser && this.activeUser.isTeacher === true) {
      return this.signupsystem(localData);
    }
    return new Promise(resolve => {
      let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/findusernames?type=findusernames&user=' + data.username + '&pass=' + data.pass + '&email=' + data.email;
      this.http
        .get(linkTest)
        .subscribe(async (data: any) => {
          if (+data.status > 0) {
            let bodyMessage = "În acest moment am găsit mai multe conturi asociate acestei adrese de email! (" + data.status + " cont / conturi). În cazul în care doriți să aflați datele Dvs. de autentificare, alegeți de mai jos butonul RECUPEREAZĂ DATE CONT. Dacă doriți să generați un cont nou, folosind aceeași adresă de email, vă rugăm să apăsați butonul DORESC UN CONT NOU!";
            let buttons = [{ label: "RECUPEREAZĂ DATE CONT", action: 0 }, { label: "DORESC UN CONT NOU", action: 1 }];
            this.modalCtrl.create({ component: GenericpopupComponent, componentProps: { title: "ATENȚIONARE!", body: bodyMessage, buttons: buttons }, backdropDismiss: false }).then(modalEl => {
              modalEl.present();
              modalEl.onDidDismiss().then(result => {
                if (+result.data.action === 0) {
                  let localhostlink = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/sendusernames?type=sendusernames&user=' + localData.username + '&pass=' + localData.pass + '&email=' + localData.email;
                  this.http
                    .get(localhostlink)
                    .subscribe(async (data: any) => {
                      this.hideLoadingOverlay();

                      let bodyMessage = "Vă rugăm să verificați căsuța de email " + localData.email + " în câteva minute. De asemenea verificați și căsuța de spam.";
                      let buttons = [{ label: "Autentificare", action: 0 }];
                      this.modalCtrl.create({ component: GenericpopupComponent, componentProps: { title: "E-mail-ul trimis cu succes", body: bodyMessage, buttons: buttons }, backdropDismiss: false }).then(modalEl => {
                        modalEl.present();
                        modalEl.onDidDismiss().then(result => {
                          ref.router.navigateByUrl("/auth/tabs/login");
                        })
                      })
                    });
                }
                else {
                  this.signupsystem(localData);
                }
              })
            })
          }
          else {
            this.signupsystem(localData);
          }
          resolve(data);
        })
    });
  }
  public signupsystem(data: SignupData) {
    let localData = data;
    let ref = this;
    //"http://localhost:5001/formidabilii-a617f/europe-west3/checkEmailExists"
    //http://localhost:5000/formidabilii-a617f/us-central1/authenticate
    return new Promise(resolve => {
      let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/authenticate?type=signup&user=' + data.username + '&pass=' + data.pass + '&email=' + data.email;
      this.http
        .get(linkTest)
        .subscribe(async (data: any) => {
          switch (+data.status) {
            case this.SIGNUP_SUCCESS:
              let token = data.data;
              let accountType: string = localData.isTeacher ? "teachers" : "students";

              let path = 'v2/users/' + localData.username;
              let vals = {};
              vals['judetId'] = localData.judetObject.id;
              vals['cityId'] = localData.cityObject.id;
              vals['unitateId'] = localData.unitateObject.id;
              vals['judetLabel'] = localData.judetObject.label;
              vals['cityLabel'] = localData.cityObject.label;
              vals['unitateLabel'] = localData.unitateObject.label;
              vals['accountType'] = accountType;

              await this.updatedata(path, vals).then(async (data: any) => {
                if (data.data === 'success') {

                  let path: string = 'v2/judete/' + localData.judetObject.id + "/localitati/" + localData.cityObject.id + "/unitati/" + localData.unitateObject.id + "/" + accountType + "/" + localData.username;
                  let vals = {};
                  vals['name'] = localData.name;
                  vals['email'] = localData.email;
                  vals['clasaId'] = localData.clasaObject.id;
                  vals['clasaLabel'] = localData.clasaObject.label;
                  vals['clasaNameId'] = localData.clasaLabelObject.id;
                  vals['clasaNameLabel'] = localData.clasaLabelObject.label;
                  vals['phone'] = localData.phone;
                  vals['selectedAvatar'] = localData.selectedAvatar;
                  await this.updatedata(path, vals).then(async (data: any) => {
                    if (data.data === 'success') {

                      if (localData.signupType === this.ACTION_TRANSITION_ACCOUNT) {
                        let path = 'users/' + this.userFirebase.uid;
                        let vals = {};
                        vals['username'] = localData.username;
                        //vals['pass'] = localData.pass;
                        await this.updatedata(path, vals);
                        this.sendUpdateAccountEmail(localData.email, localData.username);
                      }
                      if (accountType === "students") {

                        let path: string = 'v2/judete/' + localData.judetObject.id + "/localitati/" + localData.cityObject.id + "/unitati/" +
                          localData.unitateObject.id + "/classes/" + localData.clasaObject.id + "/" +
                          localData.clasaLabelObject.id + "/" + localData.username;
                        let vals = {};
                        vals['name'] = localData.name;
                        await this.updatedata(path, vals);
                      }

                      if (localData.signupType != this.ACTION_NEW_FROM_TEACHER) {
                        firebase.auth().signInWithCustomToken(token).then(function (user) {
                          ref.authdata();
                        });
                      }
                      else {
                        await this.bindStudentsAndTeacher([localData.username], this._activeUser.uid, this._activeUser.userUnitID);
                      }
                    }
                    else {
                      alert("Am întâmpinat o eroare! Cod 0x14");
                    }
                  });
                }
                else { alert("Am întâmpinat o eroare! Cod 0x15"); }
              });
              break;
            case this.SIGNUP_EXIST:
              this.isUserNameAvailable(0, localData);
              break;
            case this.SIGNUP_ERROR:
            case this.ERROR:
              alert("Am întâmpinat o eroare! Cod 0x16");
              break;
          }

          resolve(data);
        });
    });
  }
  sendUpdateAccountEmail(email, username) {
    let localhostlink = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/accountupdated?email=' + email + '&username=' + username;
    this.http
      .get(localhostlink)
      .subscribe(async (data: any) => {
        this.hideLoadingOverlay();
        // loading.dismiss();
      });
  }

  sendMailToOffice(name, email, message) {
    let localhostlink = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/sendmail?name=' + name + '&email=' + email + '&message=' + message;
    this.http
      .get(localhostlink)
      .subscribe(async (data: any) => {
        this.hideLoadingOverlay();
        // loading.dismiss();
      });
  }

  isUserNameAvailable(idx: number = 0, data) {
    let localData = data;
    //http://localhost:5000/formidabilii-a617f/us-central1/authenticate    
    let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/authenticate?type=checkuserexists&user=' + (data.username + idx) + '&pass=' + "" + '&email=' + "";
    this.http
      .get(linkTest)
      .subscribe((data: any) => {
        switch (+data.status) {
          case this.USER_EXIST:
            this.isUserNameAvailable((idx + 1), localData);
            break;
          case this.USER_NEW:
            if (this.activeUser && this.activeUser.isTeacher) {
              localData.username = localData.username + idx;
              this.signupsystem(localData);
              break;
            }
            else {
              let bodyMessage = "Pentru a te autentifica, poți folosi contul de utilizator: " + (localData.username + idx) + " și parola aleasă!";
              let buttons = [{ label: "Am înțeles", action: 0 }];
              this.modalCtrl.create({ component: GenericpopupComponent, componentProps: { title: "Actualizarea contului cu succes", body: bodyMessage, buttons: buttons }, backdropDismiss: false }).then(modalEl => {
                modalEl.present();
                modalEl.onDidDismiss().then(result => {
                  localData.username = localData.username + idx;
                  this.signupsystem(localData);
                })
              })
            }

            break;
        }
      });
  }
  public login(email: string, password: string) {
    //this._authData = authData;
    return firebase.auth().signInWithEmailAndPassword(email, password).then((data) => {
      //this.authdata();
      //console.log(data);
      this.authenticate(data.user, "", true)
      return data;
    }).catch(function (error) {
      return error;
    })
  }


  public authdata() {
    let ref = this;
    let localparams;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase.analytics().setUserId(user.uid);
        firebase.auth().currentUser.getIdToken()
          .then((idToken) => {
            ref.authenticate(user, idToken).then(async () => {
              ref.onStart.emit(ref);
            });
          })
          .catch((error) => {
            console.log(error);
            // Error occurred.
          });
        // ...
      } else {
        //ref.router.navigateByUrl("/auth/tabs/login");
      }
    });
  }

  public loginWithUser(username: string, pass: string) {
    this.logger.customLog("loginWithUser")
    username = username.toLowerCase();
    let ref = this;
    //http://localhost:5000/formidabilii-a617f/us-central1/authenticate
    let linkTest = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/authenticate?type=login&user=' + username + '&pass=' + pass + '&email=';
    this.http
      .get(linkTest)
      .subscribe((data: any) => {
        switch (+data.status) {
          case this.LOGIN_SUCCESS:
            let token = data.data;
            firebase.auth().signInWithCustomToken(token).then(function (user) {
              ref.authdata();
            });
            break;
          default:
            //TODO tratat alte cazuri de login error              
            alert("Am întâmpinat o eroare! Cod 0x17 - Parola introdusă nu este corectă!");
        }
      });
  }

  public async updateUserAccount(userUnitID: string, userID: string, isTeacher: boolean, vals) {
    let unit = userUnitID.split("_");
    let accountType = isTeacher ? "/teachers/" : "/students/"
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + accountType + userID;

    if (vals.name) {
      this.activeUser.name = vals.name;
    }
    if (vals.phone) {
      this.activeUser.phone = vals.phone;
    }
    if (vals.clasaId) {
      this.activeUser.clasaId = vals.clasaId;
    }
    if (vals.clasaLabel) {
      this.activeUser.clasaLabel = vals.clasaLabel;
    }
    if (vals.clasaNameId) {
      this.activeUser.clasaNameId = vals.clasaNameId;
    }
    if (vals.clasaNameLabel) {
      this.activeUser.clasaNameLabel = vals.clasaNameLabel;
    }
    this.updatedata(path, vals).then(() => {
      window.location.reload();
    });
  }
  public async grabStudentsForSchool(userUnitID: string) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students";
    //console.log(path);
    let dataval;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        dataval = data.data;
        dataval.key = data.key;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x22");
      }
    });
    return dataval;
  }

  public async bindStudentsAndTeacher(students: any[], teacher: string, userUnitID: string) {
    let unit = userUnitID.split("_");
    for (let l = 0; l < students.length; l++) {
      let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + students[l] + "/teachers/" + teacher;
      let vals = {};
      vals['name'] = this._activeUser.name;
      await this.updatedata(path, vals);

      let path2 = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/teachers/" + teacher + "/students/" + students[l];
      let vals2 = {};
      vals2['name'] = students[l];
      await this.updatedata(path2, vals2);
    }
    return true;
  }
  public async unbindStudentAndTeacher(student: string, teacher: string, userUnitID: string) {

    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + student + "/teachers/" + teacher;
    await this.deletedata(path).then(async (data: any) => {
      if (data.data != "fail") {
        let path2 = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/teachers/" + teacher + "/students/" + student;
        await this.deletedata(path2).then((data: any) => {
          if (data.data != "fail") {
            return true;
          }
        });
      }
    });
  }

  public async grabStudentsForTeacher(userUnitID: string, teacher: string) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/teachers/" + teacher + "/students";
    //console.log(path);
    let dataval;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        dataval = data.data;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x18");
      }
    });
    return dataval;
  }

  public async grabStudentsForStudents(userUnitID: string, userClasaId: string, userClasaNameId: string) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/classes/" + userClasaId + "/" + userClasaNameId;
    let dataval;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        dataval = data.data;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x19");
      }
    });
    return dataval;
  }
  public async grabStudents(userUnitID: string) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/";
    let dataval;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        dataval = data.data;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x20");
      }
    });
    return dataval;
  }


  public async grabStudentDataByID(userUnitID: string, userID: string) {
    let unit = userUnitID.split("_");
    let path: string = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/students/" + userID;
    let dataval;
    await this.grabdata(path).then((data: any) => {
      if (data.data != 'fail') {
        dataval = data.data;
        dataval.key = data.key;
      }
      else {
        alert("Am întâmpinat o eroare! Cod 0x21");
      }
    });
    return dataval;
  }

  public recoverPassword(email: string) {
    firebase.auth().sendPasswordResetEmail(email);
  }
  public loginAnonymously() {
    this.logger.customLog("[login.service.ts]loginAnonysmouly()" + this.activeUser);
    if (this.activeUser === null || this.activeUser === undefined) {
      firebase.auth().signInAnonymously().catch(function (error) {
        // Handle Errors here.
        //this.logger.customLog("Eroare in autentificare anonymous")
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      }).then(() => {
        // this.openTunnelPopup(5);
        //this.router.navigateByUrl("/home/tabs/shop"); //!IMPORTANT  
      });
    }
    else {
      this.grabTestsAvailable();
      // this.router.navigateByUrl("/home/tabs/shop"); //!IMPORTANT
    }
  }

  logout() {
    this.logger.customLog("[login.service.ts] logout()");
    this.storeAuthData(null);
    this._user.next(null);
    this._activeUser = null;
    this.storage.setExamData(null);
    this.storage.setExamAnswerData(null);
    //this.coordinatorCode.unsubscribe();
    //this.router.navigateByUrl("/auth/tabs/demo"); //!IMPORTANT
    //this.navCtrl.navigateRoot("/auth/tabs/demo");
    firebase.auth().signOut();
    window.location.replace("/auth/tabs/login");
  }

  public storeAuthData(
    user: User
  ) {
    if (user === null) {
      Plugins.Storage.remove({ key: 'authData' });
      return;
    }
    this.logger.customLog("[login.service.ts] storeAuthData")
    const data = JSON.stringify({
      uid: user.uid,
      email: user.email,
      name: user.name,
      phone: user.phone,
      tokenId: user.tokenId,
      isAnonymous: user.isAnonymous,
      isTeacher: user.isTeacher,
    });
    Plugins.Storage.set({ key: 'authData', value: data });
  }
}

