import { Component, OnInit, Input } from '@angular/core';
import { TranslatorService } from 'src/app/translator.service';

@Component({
  selector: 'app-rewardpopup',
  templateUrl: './rewardpopup.component.html',
  styleUrls: ['./rewardpopup.component.scss'],
})
export class RewardpopupComponent implements OnInit {

  @Input() assetType;
  @Input() examLabel;
  @Input() username;

  public pdfSrc = "";
  public facebookLink = "";
  link1 = "https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fstorage.googleapis.com%2Fformidabilii-a617f.appspot.com%2Fexams%2F";
  link2 = "%2F";
  link3 = ".png&amp;src=sdkpreparse";
  constructor(public translator: TranslatorService) { }

  ngOnInit() { }

  ionViewWillEnter() {
    this.facebookLink = this.link1 + this.username + this.link2 + this.examLabel + this.assetType + this.link3;
    this.pdfSrc = "https://storage.googleapis.com/formidabilii-a617f.appspot.com/exams/" + this.username + "/" + this.examLabel + this.assetType + ".pdf";
  }
  public facebookShare() {

  }

}
