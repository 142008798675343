// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseAPIKey:'AIzaSyB4Rj-baF7XmPwB2Js9wij8Ggp9R8FEUyQ',
  apiKey: "AIzaSyB4Rj-baF7XmPwB2Js9wij8Ggp9R8FEUyQ",
  authDomain: "formidabilii-a617f.firebaseapp.com",
  databaseURL: "https://formidabilii-a617f.firebaseio.com",
  projectId: "formidabilii-a617f",
  storageBucket: "formidabilii-a617f.appspot.com",
  messagingSenderId: "476279781655",
  appId: "1:476279781655:web:37a40fb4366d3d7cccdf52",
  measurementId: "G-CB8KW4MR07",
  pages:{
    login:true, 
    demo:true, 
    signup:true,
    exam:true,
    account:true,
    accountStudent:true,
    competition:true,
    confirm:true,
    paymentconfirmation:true,
    shop:true,
    training:true,
    home:true,
    admin:true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
