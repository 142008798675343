import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslatorService } from 'src/app/translator.service';

@Component({
  selector: 'app-studentextended',
  templateUrl: './studentextended.component.html',
  styleUrls: ['./studentextended.component.scss'],
})
export class StudentextendedComponent implements OnInit {
  @Input() userdata;
  constructor(private modalCtrl: ModalController, public translator:TranslatorService) { }
  public name;
  public clasa;
  public username;
  public results = [];
  public avatarImage;

  ngOnInit() {
    this.name = this.userdata.name;
    this.clasa = this.userdata.clasaLabel + " " + this.userdata.clasaNameLabel;
    this.username = this.userdata.username;
    this.results = this.userdata.results;
    this.avatarImage = "./../../../../assets/formidabilii/avatars/" + this.userdata.selectedAvatar + ".png";
  }

  closeUser() {
    this.modalCtrl.dismiss({});
  }
  sendMessage() {

  }
}
