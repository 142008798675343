import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-messagecard',
  templateUrl: './messagecard.component.html',
  styleUrls: ['./messagecard.component.scss'],
})
export class MessagecardComponent implements OnInit {

  @Input() user;
  @Output() onDetails: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
  public name;
  public clasa;
  public username;
  public results = [];
  public resultsLow = [];
  public headerColor = "success";
  public cardIsChat: boolean = false;

  ngOnInit() {
    if (this.user === null) {
      return;
    }
    this.name = this.user.name;
    this.clasa = this.user.clasaNameLabel + " " + this.user.clasaLabel;
    this.username = this.user.username;
    for (let i = 0; i < this.user.results.length; i++) {
      if (i < 2) {
        this.resultsLow.push(this.user.results[i]);
      }
    }
    this.results = this.resultsLow;
    this.cardIsChat = this.username === "CHAT";
    if (this.cardIsChat) {
      this.headerColor = "danger"
      this.username = "";
    }
  }

  public openDetails() {
    this.onDetails.emit();
  }

}
