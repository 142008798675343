import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-genericpopup',
  templateUrl: './genericpopup.component.html',
  styleUrls: ['./genericpopup.component.scss'],
})
export class GenericpopupComponent implements OnInit {
  @Input() title;
  @Input() body;
  @Input() buttons;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {

  }
  dismissPopup(action) {
    this.modalCtrl.dismiss({ action: action });
  }
}
