import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-demopopup',
  templateUrl: './demopopup.component.html',
  styleUrls: ['./demopopup.component.scss'],
})
export class DemopopupComponent implements OnInit {
  @Input() loginService;
  buttons = ['T','E','S','T','E',' ','S','C','O','L','A','R','E'];
  code = "";

  constructor(private modalController:ModalController) { }

  ngOnInit() {}

  async onConfirmCompetition(id)
  {
      this.code+= id;
      //let data = await this.loginService.checkCode(this.code);
      //if(data.data === true)
      //{
        //this.modalController.dismiss({});
      //}
  }
}
