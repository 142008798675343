import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController, IonSlides, IonInput } from '@ionic/angular';
import { Router } from '@angular/router';
import * as firebase from "firebase/app";
import 'firebase/analytics';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})

export class PopupComponent implements OnInit {
  @Input() pageId: number;
  @Input() results: number;
  @Input() assetHeight: number;
  @Input() errorMessage: string;
  @Input() isGuest: boolean;
  @Input() answersProvided;
  @Input() loadedQuestions;
  @Input() teacherName;
  @Input() teacherClassCode;
  @Input() teacherCoordinatorCode;
  @Input() teacherContestStudents;
  @Input() paymentData;
  @Input() paymentKey;
  @Input() dataHtml;
  @Input() paymentsArray;
  @Input() loginService;
  @Input() codeInserted: boolean = false;
  @Input() isCompetition: boolean = false;


  constructor(private modalController: ModalController, private httpClient: HttpClient, private router: Router, private http: HttpClient) { }
  CONST_DIGITAL = "1";
  CONST_PREMIUM = "2";

  paymentselected = false;
  address = "";
  addressNotValid = true;
  selectionMade = false;
  headerTitleMessage = "";
  headerContent = "";
  endExamAssetHorizontal = "";
  endExamAssetVertical = "";
  packageLabel = "";
  slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  answersList = [];
  answersColor = [];
  avatarsPath = [];
  avatarPath = "";
  public translator;
  ngOnInit() {
    this.translator = this.loginService.translator;
    this.headerTitleMessage = this.translator.lang['popupcongratulations'];
    this.packageLabel = this.translator.lang['popuppackage'];
    if (this.pageId === 2) {
      this.headerTitleMessage = "";
      this.headerContent = this.errorMessage;
      this.endExamAssetHorizontal = "./../../../assets/formidabilii/endContestHorizontal.png";
      this.endExamAssetVertical = "./../../../assets/formidabilii/endContestVertical.png";
      return;
    }
    else if (this.pageId === 3) {
      this.headerTitleMessage = "";
      this.headerTitleMessage = this.errorMessage;
      return;
    }
    else if (this.pageId === 4) {
      let total = 10;
      for (let i = 0; i < this.answersProvided.length; i++) {
        let colorTag = "primary";
        if (this.answersProvided[i].pointsValued < this.loadedQuestions[i].points) {
          colorTag = "danger";
        }
        total += +this.answersProvided[i].pointsValued;
        this.answersColor.push(colorTag);
        let val = i + 1;
        this.answersList.push(this.translator.parse('popupqval', [val, this.answersProvided[i].pointsValued, this.loadedQuestions[i].points]));
      }
      let extraLabel = "";
      if (total > 19) {
        extraLabel = this.translator.lang['popupof'];
      }
      this.headerTitleMessage = this.translator.parse('popupqval', [total, extraLabel]);
      this.answersColor.push("primary");
      this.answersList.push(this.translator.lang['popupbaseval']);
      return;
    }
    else if (this.pageId === 5) {
      this.headerTitleMessage = this.translator.lang['popupvisitor'];
      return;
    }
    else if (this.pageId === 6) {
      //this.headerTitleMessage = "Bine ați revenit, " + this.teacherName + "! Momentan nu aveți preșcolari/elevi adăugați în clasa virtuală. Oferiți codul " + this.teacherClassCode + " elevilor Dvs. pentru a îi adăuga în clasa virtuală. Astfel veți putea urmări evoluția lor pe platformă și punctajele obținute la testele săptămânale gratuite.";
      this.headerTitleMessage = this.translator.lang['popupbill'];
      return;
    }
    else if (this.pageId === 7) {
      this.headerTitleMessage = "Bine ați revenit, " + this.teacherName + "! În acest moment " + this.teacherContestStudents + " elevi au folosit codul unic de coordonator pentru a participa la concurs. Puteți oferi codul " + this.teacherCoordinatorCode + "  în continuare pentru a adăuga mai multi elevi la concurs.";
      return;
    }
    else if (this.pageId === 8) {
      this.headerTitleMessage = "Contul tău de elev nu este atașat unei clase virtuale. În cazul în care profesorul tău a format o clasă virtuală pe platforma www.testescolare.ro, solicită-i codul clasei format din 5 caractere. Dacă ai un cod format din 5 caractere, introdu-l în pagina următoare. ";
      return;
    }
    else if (this.pageId === 9) {
      this.headerTitleMessage = "Vrei să participi la concursul din 25 - 29 Mai? Introdu codul din 7 caractere, primit de la profesorul tău. Dacă nu ai un cod din 7 caractere, te poți înscrie individual, începând cu data de 14 Mai.";
      return;
    }
    else if (this.pageId === 10) {
      let len = this.paymentsArray.length;
      let paymentData = this.paymentsArray[len - 1];
      if (paymentData.action === 'confirmed_pending') {
        this.headerContent = "Plata Dvs. este în așteptare. Vom actualiza datele în contul Dvs. în cel mai scurt timp. Vă rugăm să ne contactați pentru mai multe detalii."
        return;
      }
      if (paymentData.status === '0') {
        if (+this.errorMessage === -1) {
          this.headerContent = "Felicitări pentru achiziționarea Concursului Formidabilii, pachetul în varianta " + paymentData.packageType + ". Ultima săptămână în care poți susține concursul!"
        }
        else {
          let labelPuncte = +this.errorMessage > 19 ? this.errorMessage + " de puncte" : this.errorMessage + " puncte";
          this.headerContent = "Felicitări pentru rezultatul obținut la Concursului Formidabilii, etapa din Mai 2019. Ai obținut " + labelPuncte;
        }
      }
      else {
        this.headerContent = "Ai încercat să faci o plată pentru pachetul de concurs Formidabilii la data de: " + new Date(+paymentData.key).toLocaleString() + " . Din păcate am întâmpinat o eroare în procesarea cardului, te rugăm să ne contactezi pentru mai multe detalii. Codul de eroare este: " + paymentData.status;
      }

      return;
    }
    else if (this.pageId === 11) {
      return;
    }
    else if (this.pageId === 12) {
      this.avatarsPath = [];
      let avatars = 75;
      for (let i = 0; i < avatars; i++) {
        this.avatarsPath.push("./../../../assets/formidabilii/avatars/" + i + ".png");
      }
      return;
    }
    else if (this.pageId === 13) {
      return;
    }
    let str: string = "";
    let idx: number = 0;
    if (this.results >= 90) {
      str = this.translator.lang['popupfirstplace'];
      idx = 1;
    }
    else if (this.results > 79 && this.results < 90) {
      str = this.translator.lang['popupsecondplace'];
      idx = 2;
    }
    else if (this.results > 69 && this.results < 80) {
      str = this.translator.lang['popupthirdplace'];
      idx = 3;
    }
    else if (this.results > 49 && this.results < 70) {
      str = this.translator.lang['popupfourthplace'];
      idx = 4;
    }
    else {
      str = this.translator.lang['popuplastplace'];
      idx = 5;
    }

    if (this.results < 50) {
      this.headerTitleMessage = "";
    }
    if (this.results < 20) {
      this.headerContent = this.translator.parse("popuptotal",[this.results, str]);
    }
    else {
      this.headerContent = this.translator.parse("popuptotal",[this.results, str]);
    }
    if (this.isGuest) {
      this.headerContent = this.headerContent + this.translator.lang['popupsignup'];
    }
    this.endExamAssetHorizontal = "./../../../assets/formidabilii/endContestHorizontal_" + idx + ".png";
    this.endExamAssetVertical = "./../../../assets/formidabilii/endContestVertical_" + idx + ".png"
  }
  textTyped(ev: any) {
    let newValue = ev.target.value;

    let regExp = new RegExp('^[A-Za-z0-9? ]+$');

    if (!regExp.test(newValue)) {
      ev.target.value = newValue.slice(0, -1);
    }
    this.addressNotValid = this.address.length < 15;
  }
  selectAvatar(index: any) {
    //this.loginService.updateAvatarId(index);
    this.modalController.dismiss({ idx: index }, "");
  }
  choosePackageDigital() {
    let amount = 9;
    this.openExam(amount);
  }

  choosePackagePremium() {
    let amount = 11;
    this.packageLabel = "PREMIUM - IUNIE";
    this.openExam(amount);
  }

  choosePackageCombo() {
    let amount = 99;
    this.packageLabel = "COMBO - IUNIE";
    this.openExam(amount);
  }
  choosePackageDelivery() {
    let amount = 26;
    this.packageLabel = "PREMIUM - IUNIE";
    this.openExam(amount);
  }


  public openExam(amount) {
    this.addressNotValid = true;
    this.paymentselected = true;
    let yourheaders = new HttpHeaders({
      "Content-Type": 'application/x-www-form-urlencoded'
    });


    //let options = new RequestOptions({ headers: headers });
    yourheaders.append("Content-Type", 'application/x-www-form-urlencoded');
    yourheaders.append('Access-Control-Allow-Origin', '*');
    yourheaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    yourheaders.append('Accept', 'application/json');
    yourheaders.append('content-type', 'application/json');

    let youroptions = {
      headers: yourheaders
    }
    let date = new Date().getTime();
    this.address = this.address.replace("&", ' ');
    let linkProd = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/addMessage?amount=' + amount + '&customerId=' +
      this.loginService.activeUser.uid + "_" + date + '&orderId=' + date + '&clientName=' + this.loginService.activeUser.name + '&clientEmail=' + this.loginService.activeUser.email +
      '&clientPhone=' + this.loginService.activeUser.phone + '&packageType=' + this.packageLabel + '&address=' + this.address;
    let linkDebug = 'http://localhost:5000/formidabilii-a617f/us-central1/addMessage?amount=9.00&customerId=mna&orderId=nnnn2&clientName=John&clientEmail=john@ea.com&clientPhone=021000&packageType=DIGITAL';

    let linkTest = 'http://localhost:5000/formidabilii-a617f/us-central1/testFunction';
    let linkTestProd = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/testFunction';
    this.http
      .get(linkProd, youroptions)
      .subscribe((data: any) => {
        this.paymentKey = data.env_key;
        this.paymentData = data.data;
        this.selectionMade = true;
      });
  }

  virtualClassRoom() {
    this.router.navigateByUrl("home/tabs/account");
    this.modalController.dismiss(null, "");
  }

  virtualCoordinatorRoom() {
    this.router.navigateByUrl("home/tabs/coordinator");
    this.modalController.dismiss(null, "");
  }
  insertTeacherCode() {
    this.router.navigateByUrl("home/tabs/account");
    this.modalController.dismiss(null, "");
  }

  insertCoordinatorCode() {
    this.modalController.dismiss(null, "insertCoordinatorCode");
  }
  login() {
    this.router.navigateByUrl("auth/tabs/login");
    this.modalController.dismiss(null, "");
  }
  signup() {
    this.router.navigateByUrl("auth/tabs/signup");
    this.modalController.dismiss(null, "");
  }
  confirmPurchase() {
    this.modalController.dismiss(null, "confirmpurchase")
  }

  recoverPassword() {
    this.modalController.dismiss(null, "recover")
  }
  createAccount() {
    this.modalController.dismiss(null, "createaccount")
  }
  dismissPopup() {
    this.modalController.dismiss(null, 'cancel');
  }
  facebookShare() {
    let navigatorbrowser: any;
    if (navigatorbrowser && navigatorbrowser.share) {
      navigatorbrowser.share({
        'title': 'Optional title',
        'text': 'Optional message',
        'url': 'http://www.myurl.com'
      }).then(function () {
        console.log('Successful share');
      }).catch(function (error) {
        console.log('Error sharing:', error)
      });
    }
  }
  updateAccount() {
    this.modalController.dismiss(null, 'updateaccount')
  }
  closeCompetition() {
    //  this.pageId = 1;
    this.modalController.dismiss(null, 'closecompetition')
  }
  backToMainMenu() {
    firebase.analytics().logEvent('finishcompetition');
    this.modalController.dismiss(null, 'mainmenu');
  }

  checkResults() {
    this.modalController.dismiss(null, 'checkresults');
  }

  openDiploma() {
    //this.router.navigateByUrl("home/tabs/account");
    //this.modalController.dismiss(null, "");
  }
}
