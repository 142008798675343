export class User {
  constructor(
    public uid: string,
    public email: string,
    public name: string,
    public phone: string,
    public tokenId: string,
    public isAnonymous: boolean,
    public isTeacher: boolean,
    public clasaId: string,
    public clasaLabel: string,
    public clasaNameId: string,
    public clasaNameLabel: string,
    public userUnitID: string,
    public judetLabel: string,
    public cityLabel: string,
    public unitateLabel: string,
    public teachers: any[],
    public teachersUID:any[],
    public selectedAvatar:number
  ) { }
}

export class DBUserData {
  constructor(
    public ownedItems: string,
    public teacherId: string,
    public name: string) { }
}



export class StoredData {
  constructor(
    public classId: number,
    public groupId: number,
    public isCompetition: boolean,
    public previousResults: string,
    //public examExpire: Date,
    public label: string
  ) { }
}