import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignupData {
  public username: string;
  public pass: string;
  public email: string;
  public name: string;
  public phone: string;
  public isTeacher: boolean;
  public judetObject: { id: string, label: string };
  public cityObject: { id: string, label: string };
  public unitateObject: { id: string, label: string };
  public clasaObject: { id: string, label: string };
  public clasaLabelObject: { id: string, label: string };
  public unitID: string = "";
  public isNewUserFromTeacher: boolean = false;
  public signupType: number;
  public selectedAvatar:number;
}

export class ExportService {

  constructor() { }
}
