import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-multiple-selection-popup',
  templateUrl: './multiple-selection-popup.component.html',
  styleUrls: ['./multiple-selection-popup.component.scss'],
})
export class MultipleSelectionPopupComponent implements OnInit {
  @Input() dataProvider;
  
  public items = [];
  private selectedItems = [];
  actionLabel = "Adaugă în clasă!";
  titleLabel = "Selectează elevii!"
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.items = [];
    for (let i = 0; i < this.dataProvider.length; i++) {
      this.items.push(this.dataProvider[i]);
      this.selectedItems.push(false);
    }
  }

  public selectItem(ev: any, index:number)
  {
    this.selectedItems[index] = ev.detail.checked;
  }

  public addStudentsToClass()
  {
      this.modalCtrl.dismiss({selectedItems:this.selectedItems})
  }
  public dismissPopup(event)
  {
    this.modalCtrl.dismiss({});
  }
}
