import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PopupComponent } from 'src/app/components/popup/popup.component';
import * as firebase from "firebase/app";
import 'firebase/analytics';
import { IonicSelectableComponent } from 'ionic-selectable';
import { LoggerService } from 'src/app/logger.service';
import { ExportService, SignupData } from 'src/app/v2/utils/export.service'
import { TranslatorService } from 'src/app/translator.service';

class Port {
  public id: number;
  public name: string;
}

@Component({
  selector: 'app-accountpopup',
  templateUrl: './accountpopup.component.html',
  styleUrls: ['./accountpopup.component.scss'],
})


export class AccountpopupComponent implements OnInit {

  @Input() loginService;
  @Input() configData = null;
  @Input() actionType = -1;

  constructor(private modalCtrl: ModalController, private loggerService: LoggerService) { }

  ACTION_EDIT: number = 0;
  ACTION_NEW_FROM_TEACHER: number = 1;
  ACTION_TRANSITION_ACCOUNT: number = 2;
  ACTION_NEW_SIGNUP: number = 3;


  signupData: SignupData;
  teacherNameVal = "";
  schoolNameVal = "";
  cityNameVal = "";

  public nameStudent: string;
  public userName: string;
  public phoneStudent: string;
  public emailStudent: string;
  public passwordStudent: string;
  public teacherCode: string = "";
  public translator;

  public nameTeacher: string;
  public phoneTeacher: string;
  public emailTeacher: string;
  public passwordTeacher: string;


  public isLabelSelectionVisible = false;
  public dataClass: Port[];
  public dataLabels: Port[];
  public classVal: Port;
  public classLabel: Port;

  public datalocalitati: Port[] = [];
  public dataunitati: Port[] = [];
  public datajudete: Port[] = [];

  public isUpdateAccount = false;
  public isEditAccount = false;

  port: Port;
  port2: Port;
  port3: Port;
  port4: Port;

  portelev: Port;
  port2elev: Port;
  port3elev: Port;

  private selectedJudet;
  private selectedLocalitate;
  private selectedUnitate;
  private selectedAvatar = 0;
  public avatarImage = "./../../../../assets/formidabilii/avatars/0.png";
  //private dataJudete = [];

  public updateLabel: string;
  public updateButton: string;

  public accountType: Port;
  public datamanagers: Port[] = [{ id: 0, name: "" }, { id: 1, name: "" }];
  public isFormDisabled = true;
  isAccountTeacher = false;
  isAccountStudent = false;
  isNewUserFromTeacher = false;

  signupType: number;
  unitVals = [];

  isUsernameRequired = true;
  async ngOnInit() {

    this.translator = this.loginService.translator;
    this.updateLabel = this.translator.lang['popupcreateaccount'];
    this.updateButton = this.translator.lang['acpopupdate'];
    this.datamanagers[0].name = this.translator.lang['acpopparent'];
    this.datamanagers[1].name = this.translator.lang['acpopteacher'];

    const snapshot = await firebase.database().ref('/v2/carto/judete').once('value');
    let judeteSize = snapshot.numChildren();
    for (let i = 0; i < judeteSize; i++) {
      this.datajudete.push({ id: snapshot.val()[i].id, name: snapshot.val()[i].name })
    }
    this.dataClass = [];

    for(let i=0; i < this.loggerService.dataClass.length; i++)
    {
      this.dataClass.push(this.loggerService.dataClass[i]);
      this.dataClass[i].name = this.translator.lang[this.loggerService.dataClass[i].name];
    }
    console.log(this.dataClass);
    //console.log(this.loggerService.dataClass);
    this.dataLabels = this.loggerService.dataLabels;
    if (this.configData != null) {
      this.isAccountTeacher = this.configData.isTeacher;
      this.isAccountStudent = !this.isAccountTeacher;
      if (this.actionType === this.ACTION_EDIT) {
        this.updateLabel = this.translator.lang['acpopedit'];
        this.updateButton = this.translator.lang['acpopupdatedate'];
        this.isEditAccount = true;
        this.signupType = this.ACTION_EDIT;
      }
      else if (this.actionType === this.ACTION_NEW_FROM_TEACHER) {
        this.isNewUserFromTeacher = true;
        this.accountType = this.datamanagers[0];
        this.signupType = this.ACTION_NEW_FROM_TEACHER;
      }
      else {
        this.updateLabel = this.translator.lang['acpopupdateaccount'];
        this.isUpdateAccount = true;
        this.signupType = this.ACTION_TRANSITION_ACCOUNT;
        this.updateButton = this.translator.lang['acpopupdatedate'];
      }

      this.userName = (this.configData.username != undefined && this.configData.username.length > 0) ? this.configData.username : this.configData.name;
      this.nameStudent = this.configData.name;
      this.nameTeacher = this.configData.name;
      this.emailStudent = this.configData.email;
      this.emailTeacher = this.configData.email;
      this.phoneStudent = this.configData.phone;
      this.phoneTeacher = this.configData.phone;
      this.selectedAvatar = this.configData.selectedAvatar != undefined ? this.configData.selectedAvatar : 0;
      this.avatarImage = "./../../../../assets/formidabilii/avatars/" + this.selectedAvatar + ".png"
      let unitId = this.configData.unitID;
      if (unitId != undefined && unitId != null) {
        this.unitVals = unitId.split("_");
      }

      if (this.isEditAccount) {
        this.classVal = this.dataClass[this.configData.clasaObject.id];
        this.classLabel = this.dataLabels[this.configData.clasaLabelObject.id];
        if (!this.isAccountTeacher) {
          this.isLabelSelectionVisible = this.classVal.id > 2;
        }
      }
      
      if (this.isNewUserFromTeacher || this.isEditAccount) {
        this.emailStudent = this.configData.email;
        if (this.unitVals.length === 3) {
          this.portelev = this.datajudete[+this.unitVals[0]];
          this.port = this.datajudete[+this.unitVals[0]];
          this.judetChanged(null, +this.unitVals[0]);
        }
      }
      if(!this.isEditAccount)
      {
        this.isAccepted({ e: "" });
      }
      this.isUsernameRequired = this.userName.length === 0;
    }
    else {
      this.signupType = this.ACTION_NEW_SIGNUP;
    }
  }

  async judetChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    if ((event != null && event.value != null) || forceID != null) {
      this.selectedJudet = event != null ? event.value.id : forceID;
      const snapshot = await firebase.database().ref('/v2/carto/localitati/' + this.selectedJudet).once('value');
      let localitatiSize = snapshot.numChildren();
      this.datalocalitati = [];
      for (let i = 0; i < localitatiSize; i++) {
        this.datalocalitati.push({ id: snapshot.val()[i].id, name: snapshot.val()[i].name })
      }
      if (this.unitVals.length === 3) {
        this.port2elev = this.datalocalitati[+this.unitVals[1]];
        this.port2 = this.datalocalitati[+this.unitVals[1]];
        this.localitateChanged(null, this.unitVals[1]);
      }
    }
    else {
      this.datalocalitati = [];
    }
    this.dataunitati = [];
    if (this.isAccountStudent) {
      if(this.selectedJudet===42)
      {
        this.port2elev = {id:0, name:" "};
        this.port3elev = {id:0, name:" "};
      }
      this.checkIsFormValidStudent();
    }
    else {
      if(this.selectedJudet===42)
      {
        this.port2 = {id:0, name:" "};
        this.port3 = {id:0, name:" "};
      }
      this.checkIsFormValidTeacher();
    }
  }
  async localitateChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    if ((event != null && event.value != null) || forceID != null) {
      this.selectedLocalitate = event != null ? event.value.id : forceID;
      const snapshot = await firebase.database().ref('/v2/carto/unitati/' + this.selectedJudet + "/" + this.selectedLocalitate).once('value');
      let unitatiSize = snapshot.numChildren();
      this.dataunitati = [];
      for (let i = 0; i < unitatiSize; i++) {
        this.dataunitati.push({ id: snapshot.val()[i].id, name: snapshot.val()[i].name })
      }
      if (this.unitVals.length === 3) {
        this.port3elev = this.dataunitati[+this.unitVals[2]];
        this.port3 = this.dataunitati[+this.unitVals[2]];
        this.unitatiChanged(null, this.unitVals[2]);
      }
    }
    else {
      this.dataunitati = [];
    }
    if (this.isAccountStudent) {
      this.checkIsFormValidStudent();
    }
    else {
      this.checkIsFormValidTeacher();
    }
  }
  unitatiChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    this.selectedUnitate = (event != null && event.value != null) ? event.value.id : forceID;
    if (this.isAccountStudent) {
      this.checkIsFormValidStudent();
    }
    else {
      this.checkIsFormValidTeacher();
    }
  }

  checkIsFormValidTeacher() {
    this.isFormDisabled = true;
    if (this.port3 != null && this.port3.name.length > 0) {
      this.isFormDisabled = false;
    }
  }
  checkIsFormValidStudent() {
    this.isFormDisabled = true;
    //this.classLabelToSave = "";
    if (this.isLabelSelectionVisible === false) {
      if (this.classVal != null && this.classVal.name.length > 0 && this.port3elev != null && this.port3elev.name.length > 0) {
        this.isFormDisabled = false;
        //this.classLabelToSave = this.classVal.name;
      }
    }
    else {
      if (this.classLabel != null && this.classLabel.name.length > 0 && this.port3elev != null && this.port3elev.name.length > 0) {
        this.isFormDisabled = false;
        //this.classLabelToSave = this.classVal.name + this.classLabel.name;
      }
    }
  }

  public isAccepted(e) {
    if (this.isUsernameRequired === false) {
      return;
    }
    let restricted = [" ", "/", ",", "\\", "+", "-", ")", "(", "*", "&", "^", "%", "$", "#", "!", "`", "~", ":", ";", "|", "?", ".", "[", "]"];
    let diacritics = ['ș', 'Ș', 'Ț', 'ț', 'â', 'Â', 'î', 'Î', 'ă', 'Ă'];
    let diacriticsChanged = ['s', 'S', 'T', 't', 'a', 'A', 'i', 'I', 'a', 'A'];
    if (restricted.indexOf(e.key) != -1) {
      let user = this.isAccountStudent ? this.nameStudent : this.nameTeacher;
      this.userName = user.slice(0, user.length - 1);
    }
    let name = this.isAccountStudent ? this.nameStudent : this.nameTeacher;
    let userNameLetters = [];
    if (name != undefined) {
      userNameLetters = name.split("");
    }

    for (let l = 0; l < userNameLetters.length; l++) {
      if (diacritics.indexOf(userNameLetters[l]) != -1) {
        userNameLetters[l] = diacriticsChanged[diacritics.indexOf(userNameLetters[l])];
      }
      if (restricted.indexOf(userNameLetters[l]) != -1) {
        userNameLetters[l] = "";
      }
    }
    let user = "";
    for (let k = 0; k < userNameLetters.length; k++) {
      user += userNameLetters[k];
    }
    user = user.toLowerCase();
    this.userName = user;
  }

  dismissPopup() {
    this.modalCtrl.dismiss({});
  }
  async finalCheckIsFormValid() {

    let username = this.userName;
    let pass = this.isAccountStudent ? this.passwordStudent : this.passwordTeacher;
    let email = this.isAccountStudent ? this.emailStudent : this.emailTeacher;
    let name = this.isAccountStudent ? this.nameStudent : this.nameTeacher;
    let phone = this.isAccountStudent ? this.phoneStudent : this.phoneTeacher;
    let judet = this.isAccountStudent ? this.portelev.name : this.port.name;
    let city = this.isAccountStudent ? this.port2elev.name : this.port2.name;
    let unitate = this.isAccountStudent ? this.port3elev.name : this.port3.name;

    if (this.isEditAccount && this.configData != null) {
      let updateVals = {};
      if (name != this.configData.name) {
        updateVals['name'] = name;
      }
      if (phone != this.configData.phone) {
        updateVals['phone'] = phone;
      }

      if (this.classVal != null && this.classVal.id != this.configData.clasaObject.id) {
        updateVals['clasaId'] = this.classVal.id;
        updateVals['clasaLabel'] = this.classVal.name;
      }

      if (this.classLabel != null && this.classLabel.id != this.configData.clasaLabelObject.id) {
        updateVals['clasaNameId'] = this.classLabel.id;
        updateVals['clasaNameLabel'] = this.classLabel.name;
      }

      if (this.selectedAvatar != this.configData.selectedAvatar) {
        updateVals['selectedAvatar'] = this.selectedAvatar;
      }
      
      this.modalCtrl.dismiss({ isEditAccount: true, updateVals: updateVals });
      return;
    }
    if (name != null && phone != null && email != null && pass != null && this.isFormDisabled === false) {
    }
    else {
      this.openPopup( this.translator.lang['acpopupalert'], false);
      return;
    }
    this.signupData = new SignupData();
    this.signupData.username = username;
    this.signupData.pass = pass;
    this.signupData.email = email;
    this.signupData.name = name;
    this.signupData.phone = phone;
    this.signupData.isTeacher = !this.isAccountStudent;
    this.signupData.judetObject = { id: this.selectedJudet, label: judet };
    this.signupData.cityObject = { id: this.selectedLocalitate, label: city };
    this.signupData.unitateObject = { id: this.selectedUnitate, label: unitate };
    if (this.isAccountStudent) {
      this.signupData.clasaObject = { id: "" + this.classVal.id, label: this.classVal.name };
    }
    else {
      this.signupData.clasaObject = { id: "", label: "" };
    }
    if (this.classLabel != null) {
      this.signupData.clasaLabelObject = { id: "" + this.classLabel.id, label: this.classLabel.name };
    }
    else {
      this.signupData.clasaLabelObject = { id: "", label: "" };
    }
    this.signupData.isNewUserFromTeacher = this.isNewUserFromTeacher;
    this.signupData.signupType = this.signupType;
    this.signupData.selectedAvatar = this.selectedAvatar;

    await this.loginService.signup(this.signupData).then((data: any) => {
      this.clearFields();
      this.modalCtrl.dismiss({});
    });

  }

  classChanged(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    if (this.classVal) {
      this.isLabelSelectionVisible = this.classVal.id > 2;
      this.checkIsFormValidStudent();
    }
  }


  private openPopup(str: string, success: boolean) {
    let errorInternalCode = (success === true) ? 2 : -1;
    this.modalCtrl.create({ 
      component: PopupComponent, 
      componentProps: {loginService: this.loginService, pageId: 2, results: errorInternalCode, assetHeight: 0, errorMessage: str }, 
      backdropDismiss: false,
        cssClass: "modal-halfscreen" 
    }).then(modalEl => {
      modalEl.present();
      modalEl.onDidDismiss().then(result => {
        if (result.role === 'updateaccount') {
          //this.createAccount();
        }
      })
    })
  }

  clearFields() {
    this.nameStudent = "";
    this.userName = "";
    this.phoneStudent = "";
    this.emailStudent = "";
    this.passwordStudent = "";
    this.teacherCode = "";

    this.nameTeacher = "";
    this.phoneTeacher = "";
    this.emailTeacher = "";
    this.passwordStudent = "";

    this.port = null;
    this.port2 = null;
    this.port3 = null;
    this.port4 = null;

    this.portelev = null;
    this.port2elev = null;
    this.port3elev = null;

    this.classVal = null;
    this.classLabel = null;
  }
  ionViewWillEnter() {
    //console.log("[signup.page.ts] ionViewWillEnter()")
  }
  onChange(form: NgForm) {
    // console.log(form);
  }

  changeAvatar() {
    this.modalCtrl.create({
      component: PopupComponent,
      cssClass: "modal-selectable",
      componentProps: {
        pageId: 12,
        loginService: this.loginService
      },
      backdropDismiss: false
    }).then(modalEl => {
      modalEl.present();
      modalEl.onDidDismiss().then(result => {
        this.selectedAvatar = +result.data.idx;
        this.avatarImage = "./../../../../assets/formidabilii/avatars/" + this.selectedAvatar + ".png";
      })
    })
  }
  managerChanged(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    if (event.value != null) {
      this.userName = "";
      this.isAccountStudent = event.value.id === 0 ? true : false;
      this.isAccountTeacher = !this.isAccountStudent;
      if (this.isAccountStudent) {
        this.checkIsFormValidStudent();
      }
      else {
        this.checkIsFormValidTeacher();
      }
    }
    else {
      this.isAccountStudent = this.isAccountTeacher = false;
      this.isFormDisabled = true;
    }
  }

}
