import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoggerService } from 'src/app/logger.service';

@Component({
  selector: 'app-dropdownmodal',
  templateUrl: './dropdownmodal.component.html',
  styleUrls: ['./dropdownmodal.component.scss'],
})
export class DropdownmodalComponent implements OnInit {
  @Input() currentAsset:string;
  @Input() optionAssets:[];
  @Input() aspectRatioHeight:string;
  @Input() aspectAssetHeight:string;
  @Input() selectedIndex:string;

  columnSize = 4;

  constructor(private modalCtrl:ModalController, private logger:LoggerService) { }

  ngOnInit() {
    
  }
  onCancel()
  {
    this.modalCtrl.dismiss(null, "cancel")
  }
  public imageSelected(ev: any) {
    this.logger.customLog("[dropdownmodal.component.ts]:imageSelected()" + ev.detail.value);
    this.modalCtrl.dismiss({idx:ev.detail.value}, "confirm")
  }
}
