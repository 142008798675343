import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-genericpopupimage',
  templateUrl: './genericpopupimage.component.html',
  styleUrls: ['./genericpopupimage.component.scss'],
})
export class GenericpopupimageComponent implements OnInit {

  @Input() title;
  @Input() img;
  @Input() buttons;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  dismissPopup(action) {
    this.modalCtrl.dismiss({ action: action });
  }

}
