import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  public lang = [];
  public loginService;
  constructor() { }
  public setLoginService(param) {
    this.loginService = param;
  }
  public async loadLanguage(id, code) {
    let path = "/lang/" + code;
    await this.loginService.grabdata(path).then((data: any) => {
      if (data != 'fail') {
        if (data.data != null) {
          this.lang = data.data;
        }
      }
      else {
      }
    });
  }

  public parse(text: string, params): string {
    let val: string = this.lang[text];
    for (let i = 0; i < params.length; i++) {
      val = val.replace("~", params[i]);
    }
    return val;
  }
}
