import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PopupComponent } from './components/popup/popup.component';
import { DropdownmodalComponent } from './components/dropdownmodal/dropdownmodal.component';
import { IonicStorageModule } from '@ionic/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AccountpopupComponent } from './v2/components/accountpopup/accountpopup.component';
import { MultipleSelectionPopupComponent } from './v2/components/multiple-selection-popup/multiple-selection-popup.component';
import { StudentextendedComponent } from './v2/components/studentextended/studentextended.component';
import { PurchasepopupComponent } from './v2/components/purchasepopup/purchasepopup.component';
import { RewardpopupComponent } from './v2/components/rewardpopup/rewardpopup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MultiplePurchasePopupComponent } from './v2/components/multiple-purchase-popup/multiple-purchase-popup.component';
import { AdminselectorComponent } from './v2/components/adminselector/adminselector.component';
import { BillspopupComponent } from './v2/components/billspopup/billspopup.component';
import { MessengerComponent } from './v2/components/messenger/messenger.component';
import { GenericpopupComponent } from './v2/components/genericpopup/genericpopup.component';
import { DemopopupComponent } from './v2/components/demopopup/demopopup.component';
import { ChatroomComponent } from './v2/components/chatroom/chatroom.component';
import { AngularSvgIconModule } from 'angular-svg-icon';


import { enableProdMode } from '@angular/core';
import { GenericpopupimageComponent } from './v2/components/genericpopupimage/genericpopupimage.component';
import { MessagecardComponent } from './v2/components/messagecard/messagecard.component';
import { CacheModule } from 'ionic-cache';
import { RewardsComponent } from './v2/components/rewards/rewards.component';
import { QuestionpopupComponent } from './v2/components/questionpopup/questionpopup.component';
enableProdMode();

@NgModule({
  declarations: [AppComponent, PopupComponent, MessagecardComponent, ChatroomComponent, DropdownmodalComponent, DemopopupComponent, MessengerComponent, AccountpopupComponent, QuestionpopupComponent, GenericpopupComponent, GenericpopupimageComponent, AdminselectorComponent, RewardpopupComponent, MultipleSelectionPopupComponent, BillspopupComponent, MultiplePurchasePopupComponent, StudentextendedComponent, PurchasepopupComponent],
  entryComponents: [PopupComponent, DropdownmodalComponent, MessagecardComponent, ChatroomComponent, AccountpopupComponent, DemopopupComponent, MessengerComponent, QuestionpopupComponent, GenericpopupComponent, GenericpopupimageComponent, AdminselectorComponent, RewardpopupComponent, MultipleSelectionPopupComponent, BillspopupComponent, MultiplePurchasePopupComponent, StudentextendedComponent, PurchasepopupComponent],
  imports: [BrowserModule, AngularSvgIconModule, HttpClientModule, FormsModule, PdfViewerModule, ReactiveFormsModule, IonicSelectableModule, CacheModule.forRoot(), IonicModule.forRoot(), AppRoutingModule, IonicStorageModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    FileOpener
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
