import { Component } from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
import 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { LoginService } from './auth/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from './auth/login/chat.service';
import { ChatroomComponent } from './v2/components/chatroom/chatroom.component';
import { LoggerService } from './logger.service';
import { Subject } from 'rxjs';
import { DemopopupComponent } from './v2/components/demopopup/demopopup.component';
import { CacheService } from 'ionic-cache';
import { TranslatorService } from './translator.service';

const subjectref = new Subject<number>();


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  public isHidden: boolean = true;
  public isHiddenNew: boolean = true;
  public chatrooms = [];
  public chatmodals = [];
  public lang = "Română";
  public customSide = "start";
  public isMobile = false;
  public currentLang = 0;
  private paramsObject;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private loginService: LoginService,
    private routerService: Router,
    private logger: LoggerService,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private translator: TranslatorService,
    cache: CacheService
  ) {
    cache.setDefaultTTL(60 * 60 * 24 * 2);
    this.initializeApp();

    firebase.initializeApp(environment);
    loginService.setAppRef(this);
    loginService.onStart.subscribe(this.onStartEmitted);
    this.isMobile = this.logger.getPlatorm() === "MOBILE";
    /*
    this.modalCtrl.create({
      component: DemopopupComponent,
      componentProps:
      {
        loginService: this.loginService
      },
      cssClass: "modal-fullscreen",
      backdropDismiss: false,
      id: "popupdemo"
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    })
    return;
*/

    this.route.queryParams.subscribe(async params => {
      let token = "";
      let date = "";
      let accountid = "";

      if (params && params.customAR) {
        let path = "v2/isAR/"
        let isAROn = false;
        await this.loginService.grabdata(path).then((data: any) => {
          if (data != 'fail') {
            if (data.data != null && data.data.status != null) {
              isAROn = data.data.status === "confirmed";
              if (isAROn) {
                this.loginService.isARFeatureOn = true;
                this.routerService.navigateByUrl("/auth/tabs/ar");
                return;

              }
            }
          }
          else {
          }
        });

      }
      this.selectLanguage(this.currentLang, true);
      if (params && params.token && params.date && params.accountid) {
        this.paramsObject = { token: params.token, date: params.date, accountid: params.accountid }
        this.routerService.navigateByUrl("/auth/tabs/demo");
      }
      else if (params && params.customToken) {
        this.loginService.authenticate(null, null, false, true, params.customToken);
        //this.routerService.navigateByUrl("/auth/tabs/demo");
      }
      if (token.length > 0 && date.length > 0 && accountid.length > 0) {
        //http://localhost:8100/auth/tabs/demo?token=bd9558ba84f8f6ef91c087b43769a55d4696db55942840eec78a99a8d72ab3647791622a545e86aa27e6d1deeba23367604b&date=1603194113931&accountid=testseb1
        //routerService.navigateByUrl("/auth/tabs/demo"); //!IMPORTANT
        //return;
      }
    });

  }
  public selectLanguage(id, isFirstRun = false) {
    let code = ""
    if(this.loginService.oneTimePopup === false && !isFirstRun)
    {
      this.loginService.oneTimePopup = true;
      let buttons = [{ label: "OK", action: 0 }];
      this.loginService.openGenericPopup(
        "ALERT",
        "<div style='color: rgb(212, 212, 212); background-color: rgb(30, 30, 30); font-family: Consolas, 'Courier New', monospace; font-weight: normal; font-size: 14px; line-height: 19px; white-space: pre; text-align: center;'><span style='color: #ce9178;'><strong><span style='font-family: Arial, Helvetica, sans-serif; text-shadow: rgba(224, 163, 163, 0.8) 3px 3px 2px;'>Această funcționalitate este &icirc;n lucru!<br><br><br>Ez a funkci&oacute; folyamatban van!<br><br><br>This feature is work in progress!<br><br><br> هذه الميزة قيد العمل</span></strong></span></div>",
        buttons
      )
    }
    switch (id) {
      case 0:
        this.lang = "Română";
        code = "ro";
        break;
      case 1:
        this.lang = "Magyar";
        code = "hu";
        break;
      case 2:
        this.lang = "English";
        code = "uk";
        break;
      case 3:
        this.lang = "عربى";
        code = "ar";
        break;
      default:
        this.lang = "Română";
        code = "ro";
    }
    this.currentLang = id;
    if (code != "ro") {
      this.loginService.languagePath = code + "/";
    }
    else {
      this.loginService.languagePath = "";
    }
    this.translator.loadLanguage(id, code);
    if (!isFirstRun) {
      this.loginService.grabCustomTests();
    }
  }
  public async onStartEmitted(ref) {
    let rooms = [];
    if (ref.activeUser.isTeacher) {
      //rooms = [ref.activeUser.uid]
    }
    else {
      //rooms = ref.activeUser.teachersUID;
    }

    if (rooms.length > 0) {
      for (let i = 0; i < rooms.length; i++) {
        let unit = ref.activeUser.userUnitID.split("_");
        let path = 'v2/judete/' + unit[0] + "/localitati/" + unit[1] + "/unitati/" + unit[2] + "/" + 'teachers' + "/" + rooms[i];
        await ref.grabdata(path).then(async (data: any) => {
          if (data.data != 'fail') {
            let internalpath = "v2/chatrooms/" + ref.activeUser.userUnitID + "_" + rooms[i];
            await ref.grabdata(internalpath + "/count").then(async (internaldata: any) => {
              if (internaldata.data != 'fail') {
                if (internaldata.data === null) {
                  let vals = {};
                  vals['count'] = 0;
                  await ref.updatedata(internalpath, vals);
                }
                let avatarid = data.data.selectedAvatar === undefined ? 0 : +data.data.selectedAvatar;
                ref.componentRef.chatrooms.push({ username: rooms[i], name: data.data.name, avatarid: avatarid });
                ref.componentRef.createChatModal(i);
              }
            });
          }
        })
      }
      ref.componentRef.isHidden = true;
    }
  }
  public createChatModal(idx) {
    this.modalController.create({
      component: ChatroomComponent,
      componentProps: {
        user: this.loginService.activeUser,
        chatroom: this.chatrooms[idx],
        loginservice: this.loginService,
        closeChatroom: this.closeChatroom,
        idx: idx,
        appref: this,
        subj: subjectref
      },
      backdropDismiss: false,
      cssClass: this.isMobile ? "modal-fullscreen" : "modal-halfscreen",
    }).then(modalEl => {
      modalEl.hidden = true;
      modalEl.present();
      this.chatmodals.push(modalEl);
    })
  }
  closeChatroom(ref, idx) {
    ref.chatmodals[idx].hidden = true;
  }
  openChatRoom(idx) {
    subjectref.next(1);
    this.chatmodals[idx].hidden = false;
    this.chatmodals[idx].scroll();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}

