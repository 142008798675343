import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatService } from 'src/app/auth/login/chat.service';
import { User } from 'src/app/auth/login/user.model';
import * as firebase from "firebase/app";
import { LoggerService } from 'src/app/logger.service';
import { IonContent, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-chatroom',
  templateUrl: './chatroom.component.html',
  styleUrls: ['./chatroom.component.scss'],
})

export class ChatroomComponent implements OnInit {
  @Input() user: User;
  @Input() chatroom;
  @Input() loginservice;
  @Input() closeChatroom;
  @Input() isNewMessage: boolean;
  @Input() idx;
  @Input() appref;
  @Input() subj;
  constructor(private chatService: ChatService, private logger: LoggerService, private modalCtrl: ModalController) { }
  public platform;
  public messages = [];
  public currentHeader = 0;
  public activeUsername;
  public chatmessage;
  private chatPath;
  @ViewChild('contentelem', { static: true }) content: IonContent;

  ngOnInit() {

    this.subj.subscribe({
      next: (v) => (this.scroll())
    });
    this.platform = this.logger.getPlatorm();
    this.activeUsername = this.user.uid;
    this.chatPath = 'v2/chatrooms/' + this.user.userUnitID + "_" + this.chatroom.username;
    var starCountRef = firebase.database().ref(this.chatPath);
    var ref = this;
    starCountRef.on('value', async function (snapshot) {
      let messages = [];
      snapshot.forEach(function (childSnapshot) {
        var childKey: string = childSnapshot.key;
        var childData = childSnapshot.val();
        if (childKey != 'count') {
          messages.push({ timestamp: childKey, data: childData })
        }
      });
      messages.sort((a, b) => +a.timestamp - +b.timestamp);
      if (messages.length === 0) {
        return;
      }

      if (ref.currentHeader != messages[messages.length - 1].timestamp) {
        for (let i = 0; i < messages.length; i++) {
          if (+messages[i].timestamp > +ref.currentHeader) {
            ref.messages.push(messages[i]);
          }
        }
        ref.isNewMessage = true;
      }
      //this.ref.chatlabel = "Mesaj nou";
      ref.appref.isHiddenNew = false;
      ref.currentHeader = messages[messages.length - 1].timestamp;
      await ref.loginservice.delay(300);
      ref.content.scrollToBottom(messages.length * 30);
    });

  }
  public async scroll() {
    await this.loginservice.delay(300);
    this.content.scrollToBottom(this.messages.length * 30);
  }
  dismissmodal() {
    //firebase.database().ref(this.chatPath).off(); 
    this.appref.isHiddenNew = true;
    this.closeChatroom(this.appref, this.idx);
  }
  sendMessage() {
    this.loginservice.pushmessage(this.chatPath, this.chatmessage, this.user.uid, this.user.name, this.user.selectedAvatar);
    this.chatmessage = "";
  }

}
