import { Component, OnInit } from '@angular/core';
import * as firebase from "firebase/app";
import { IonicSelectableComponent } from 'ionic-selectable';
import { ModalController } from '@ionic/angular';

class Port {
  public id: number;
  public name: string;
}

@Component({
  selector: 'app-adminselector',
  templateUrl: './adminselector.component.html',
  styleUrls: ['./adminselector.component.scss'],
})
export class AdminselectorComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  public datalocalitati: Port[] = [];
  public dataunitati: Port[] = [];
  public datajudete: Port[] = [];
  private selectedJudet;
  private selectedLocalitate;
  private selectedUnitate;
  public port: Port;
  public port2: Port;
  public port3: Port;
  public port4: Port;

  public async ngOnInit() {
    const snapshot = await firebase.database().ref('/v2/carto/judete').once('value');
    let judeteSize = snapshot.numChildren();
    for (let i = 0; i < judeteSize; i++) {
      this.datajudete.push({ id: snapshot.val()[i].id, name: snapshot.val()[i].name })
    }
  }

  public async judetChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    if ((event != null && event.value != null) || forceID != null) {
      this.selectedJudet = event != null ? event.value.id : forceID;
      const snapshot = await firebase.database().ref('/v2/carto/localitati/' + this.selectedJudet).once('value');
      let localitatiSize = snapshot.numChildren();
      this.datalocalitati = [];
      for (let i = 0; i < localitatiSize; i++) {
        this.datalocalitati.push({ id: snapshot.val()[i].id, name: snapshot.val()[i].name })
      }
    }
    else {
      this.datalocalitati = [];
    }
    this.dataunitati = [];
  }

  public async localitateChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    if ((event != null && event.value != null) || forceID != null) {
      this.selectedLocalitate = event != null ? event.value.id : forceID;
      const snapshot = await firebase.database().ref('/v2/carto/unitati/' + this.selectedJudet + "/" + this.selectedLocalitate).once('value');
      let unitatiSize = snapshot.numChildren();
      this.dataunitati = [];
      for (let i = 0; i < unitatiSize; i++) {
        this.dataunitati.push({ id: snapshot.val()[i].id, name: snapshot.val()[i].name })
      }
    }
    else {
      this.dataunitati = [];
    }
  }
  public unitatiChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    this.selectedUnitate = (event != null && event.value != null) ? event.value.id : forceID;
  }

  public displayResults() {
    let judetLabel = this.selectedJudet != undefined ? this.datajudete[this.selectedJudet].name : "";
    let localitateLabel = this.selectedLocalitate != undefined ? this.datalocalitati[this.selectedLocalitate].name : "";
    let unitateLabel = this.selectedUnitate != undefined ? this.dataunitati[this.selectedUnitate].name : "";
    this.modalCtrl.dismiss({
      judet: this.selectedJudet, localitate: this.selectedLocalitate, unitate: this.selectedUnitate,
      judetLabel: judetLabel, localitateLabel: localitateLabel, unitateLabel: unitateLabel
    });
  }

}
