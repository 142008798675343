import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from "firebase/app";
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  public async subscribeToChat(user: User, chatroom: string) {
    let path = 'v2/chatrooms/' + user.userUnitID + "_" + chatroom;
    var starCountRef = firebase.database().ref(path);
    starCountRef.on('value', function (snapshot) {
      return "temp";
    });
  }
}
