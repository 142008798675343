import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-purchasepopup',
  templateUrl: './purchasepopup.component.html',
  styleUrls: ['./purchasepopup.component.scss'],
})
export class PurchasepopupComponent implements OnInit {

  constructor(private http: HttpClient, private modalController: ModalController) { }
  @Input() shopItemData;
  @Input() activeUser;
  @Input() paymentConfig;
  @Input() loginService;
  @Input() idx;
  @Input() itemIdx;
  @Input() endDate;
  public paymentKey;
  public paymentData;
  public formIsActive: boolean = false;
  ngOnInit() {
    //this.initPurchase();
    this.initPurchase();
  }
  public async submitConfirmed(elem) {
    
    //this.modalController.dismiss({ status: 'success' });
    //await this.loginService.initPaymentToDB(this.idx, this.itemIdx, this.loginService.activeUser.uid, this.endDate, -1);
    elem.submit();
  }
  public initPurchase() {
    let yourheaders = new HttpHeaders({
      "Content-Type": 'application/x-www-form-urlencoded'
    });
    //let options = new RequestOptions({ headers: headers });
    yourheaders.append("Content-Type", 'application/x-www-form-urlencoded');
    yourheaders.append('Access-Control-Allow-Origin', '*');
    yourheaders.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    yourheaders.append('Accept', 'application/json');
    yourheaders.append('content-type', 'application/json');

    let youroptions = {
      headers: yourheaders
    }

    let amount = this.activeUser.isTeacher ? this.shopItemData.price : this.shopItemData.priceval;
    let packageLabel = this.shopItemData.label;
    let date = new Date().getTime();
    let isMultiple: boolean = false;
    let studentsList = "";
    let examsPurchased = "";
    let billID = 0;
    if (this.paymentConfig != undefined) {
      amount = this.paymentConfig.amount;
      isMultiple = true;
      studentsList = this.paymentConfig.studentsList;
      examsPurchased = this.paymentConfig.examsPurchased;
      billID = this.paymentConfig.billID;
    }
    let linkProd = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/addMessage?amount=' + amount + '&customerId=' +
      this.activeUser.uid + '&orderId=' + date + '&clientName=' + this.activeUser.name + '&clientEmail=' + this.activeUser.email +
      '&clientPhone=' + this.activeUser.phone + '&packageType=' + packageLabel + '&uniqueItemID=' + this.shopItemData.uniqueID +
      '&uniqueUnitID=' + this.activeUser.uniqueUnitID + '&address=temp' + '&isMultiple=' + isMultiple + '&studentsList=' + studentsList
      + '&examsPurchased=' + examsPurchased + '&billID=' + billID;
    //http://localhost:5000/formidabilii-a617f/us-central1/initPayment
    let linkDebug = 'https://europe-west3-formidabilii-a617f.cloudfunctions.net/initPayment?amount=' + amount + '&customerId=' +
      this.activeUser.uid + '&orderId=' + date + '&clientName=' + this.activeUser.name + '&clientEmail=' + this.activeUser.email +
      '&clientPhone=' + this.activeUser.phone + '&packageType=' + packageLabel + '&uniqueItemID=' + this.shopItemData.uniqueID +
      '&uniqueUnitID=' + this.activeUser.userUnitID + '&address=temp' + '&isMultiple=' + isMultiple + '&studentsList=' + studentsList
      + '&examsPurchased=' + examsPurchased + '&billID=' + billID
    //console.log(linkDebug);
    this.http
      .get(linkDebug, youroptions)
      .subscribe((data: any) => {
        this.paymentKey = data.env_key;
        this.paymentData = data.data;
        this.formIsActive = true;
      });
  }
}
