import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
class Port {
  public id: number;
  public name: string;
}

@Component({
  selector: 'app-multiple-purchase-popup',
  templateUrl: './multiple-purchase-popup.component.html',
  styleUrls: ['./multiple-purchase-popup.component.scss'],
})
export class MultiplePurchasePopupComponent implements OnInit {
  @Input() dataProvider;
  @Input() currentUser;
  @Input() isDigital;

  public items = [];
  private selectedItems = [];
  actionLabel = "PLATĂ CARD BANCAR";
  actionLabelBill = "SOLICITĂ FACTURĂ";
  titleLabel = "Selectează elevii pentru plată!";
  titleLabelPaid = "Platiti: "
  startStudents = 0;
  currentStudents = 0;
  currentStartAmoundPaid = 0;
  amountToPay = 0;
  portagents: Port;
  currentAgent = "";
  public dataagents: Port[] = [];

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.dataagents.push({ id: 5, name: 'FACEBOOK' });
    this.dataagents.push({ id: 0, name: 'ILONA' });
    this.dataagents.push({ id: 1, name: 'BOGDAN' });
    this.dataagents.push({ id: 2, name: 'CLARA' });
    this.dataagents.push({ id: 3, name: 'NICU' });
    this.dataagents.push({ id: 4, name: 'ALEXANDRA' });
    this.dataagents.push({ id: 6, name: 'ALTĂ SURSĂ' });

    this.items = [];
    for (let i = 0; i < this.dataProvider.length; i++) {
      this.items.push(this.dataProvider[i]);
      this.selectedItems.push(false);
      if (this.dataProvider[i].testData != undefined && this.dataProvider[i].testData.owner === this.currentUser.uid &&
        (this.dataProvider[i].testData.status === '0' || this.dataProvider[i].testData.status === 'confirmed')) {
        this.startStudents++;
      }
    }
    this.titleLabelPaid = "Plată confirmată: " + this.startStudents;
    //console.log(this.startStudents);
    if (!this.isDigital) {
      this.actionLabelBill = "SOLICITĂ FACTURĂ (MIN. 10 ELEVI)";
      this.actionLabel = "SOLICITĂ FACTURĂ (MIN 10 elevi)";

      if (this.startStudents < 20) {
        this.currentStartAmoundPaid = this.startStudents * 13;
      }
      else if (this.startStudents >= 20 && this.startStudents < 50) {
        this.currentStartAmoundPaid = this.startStudents * 10;
      }
      else if (this.startStudents >= 50 && this.startStudents < 100) {
        this.currentStartAmoundPaid = this.startStudents * 9;
      }
      else {
        this.currentStartAmoundPaid = this.startStudents * 8;
      }
    }
    else {
      if (this.startStudents < 20) {
        this.currentStartAmoundPaid = this.startStudents * 10;
      }
      else {
        this.currentStartAmoundPaid = this.startStudents * 8;
      }
    }

  }
  async agentChanged(event: {
    component: IonicSelectableComponent,
    value: any
  } = null, forceID = null) {
    this.currentAgent = this.portagents.name;
  }
  public selectItem(ev: any, index: number) {
    this.selectedItems[index] = ev.detail.checked;
    if (ev.detail.checked === true) {
      this.currentStudents++;
    }
    else {
      this.currentStudents--;
    }
    let labelPackageType = !this.isDigital ? "Pachet LIVRARE" : "Pachet DIGITAL";
    if (this.currentStudents === 0) {
      this.titleLabel = "Selectează elevii pentru plată!" + " " + labelPackageType;
    }
    else if (this.currentStudents === 1) {
      this.titleLabel = "Selectează elevii pentru plată! - 1 elev" + " " + labelPackageType;
    }
    else if (this.currentStudents > 1) {
      this.titleLabel = "Selectează elevii pentru plată! - " + this.currentStudents + " elevi" + " " + labelPackageType;
    }

    if (!this.isDigital) {
      if ((this.startStudents + this.currentStudents) < 20) {
        this.amountToPay = (this.startStudents + this.currentStudents) * 13 - this.currentStartAmoundPaid;
      }
      else if ((this.startStudents + this.currentStudents) >= 20 && (this.startStudents + this.currentStudents) < 50) {
        this.amountToPay = (this.startStudents + this.currentStudents) * 10 - this.currentStartAmoundPaid;
      }
      else if ((this.startStudents + this.currentStudents) >= 50 && (this.startStudents + this.currentStudents) < 100) {
        this.amountToPay = (this.startStudents + this.currentStudents) * 9 - this.currentStartAmoundPaid;
      }
      else if (this.startStudents + this.currentStudents >= 100) {
        this.amountToPay = (this.startStudents + this.currentStudents) * 8 - this.currentStartAmoundPaid;
      }
    }
    else {
      if ((this.startStudents + this.currentStudents) < 20) {
        this.amountToPay = (this.startStudents + this.currentStudents) * 10 - this.currentStartAmoundPaid;
      }
      else {
        this.amountToPay = (this.startStudents + this.currentStudents) * 8 - this.currentStartAmoundPaid;
      }
    }

  }

  public purchaseForMultipleStudents() {
    this.modalCtrl.dismiss({ type: "paynow", selectedItems: this.selectedItems, amountToPay: this.amountToPay, agent: this.currentAgent, alreadyPaidCount: this.startStudents })
  }

  public requestBill() {
    this.modalCtrl.dismiss({ type: "bill", selectedItems: this.selectedItems, amountToPay: this.amountToPay, agent: this.currentAgent, alreadyPaidCount: this.startStudents })
  }

  public dismissPopup(event) {
    this.modalCtrl.dismiss({});
  }
}
