import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';


const valueformon = "temp";

@Injectable({
  providedIn: 'root'
})

export class LoggerService {

  constructor(private plt: Platform) { }

  public customLog(param) {

    //console.log(param);

  }
  public getPlatorm() {
    let platform = "WEB";
    if (this.plt.width() < 820) {
      platform = "MOBILE";
    }
    else if (this.plt.width() >= 820) {
      platform = "WEB";
    }
    else if (this.plt.width() > 1020 && this.plt.height() > 1200) {
      platform = "IPAD";
    }
    return platform;
  }
  public nbv() {
    return "okn0";
  }
  public activEmails = [{}];

  public accounts: [{ id: 1, name: 'Alexandra' }, { id: 2, name: 'Bogdan' }, { id: 3, name: 'Ilona' }, { id: 4, name: 'Clara' }, { id: 5, name: 'Nicu' }, { id: 6, name: 'Mihaela' }, { id: 7, name: 'Florentina' }];
  public primaryValueItems = [
    { expanded: false, label: "GRĂDINIȚĂ 3 - 4 ANI", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 1 },
    { expanded: false, label: "GRĂDINIȚĂ 4 - 5 ANI", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 2 },
    { expanded: false, label: "GRĂDINIȚĂ 5 - 6 ANI", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 3 },
    { expanded: false, label: "CLASA PREGĂTITOARE", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 4 },
    { expanded: false, label: "CLASA I", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 5 },
    { expanded: false, label: "CLASA A II-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 6 },
    { expanded: false, label: "CLASA A III-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 7 },
    { expanded: false, label: "CLASA A IV-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 8 },
    { expanded: false, label: "CLASA A V-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 9 },
    { expanded: false, label: "CLASA A VI-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 10 },
    { expanded: false, label: "CLASA A VII-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 11 },
    { expanded: false, label: "CLASA A VIII-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 12 }
  ];

  public primaryValueItems2 = [
    { expanded: false, label: "GRĂDINIȚĂ 3 - 4 ANI", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 1 },
    { expanded: false, label: "GRĂDINIȚĂ 4 - 5 ANI", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"] ,  ["Test 6", "options-outline", "false", "primary", "hidden", "no"],["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 2 },
    { expanded: false, label: "GRĂDINIȚĂ 5 - 6 ANI", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 3 },
    { expanded: false, label: "CLASA PREGĂTITOARE", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 4 },
    { expanded: false, label: "CLASA I", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "no"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 5 },
    { expanded: false, label: "CLASA A II-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 6 },
    { expanded: false, label: "CLASA A III-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 7 },
    { expanded: false, label: "CLASA A IV-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "purchaseable", "no"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Test 4", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 6", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 8 },
    { expanded: false, label: "CLASA A V-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 9 },
    { expanded: false, label: "CLASA A VI-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 10 },
    { expanded: false, label: "CLASA A VII-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 11 },
    { expanded: false, label: "CLASA A VIII-A", items: [["Test 1", "medal-outline", "false", "secondary", "active", "no"], ["Test 2", "book-outline", "false", "primary", "inactive", "no"], ["Test 3", "options-outline", "false", "primary", "hidden", "no"], ["Test 4", "options-outline", "false", "primary", "hidden", "no"], ["Concurs - MAI", "trophy-outline", "false", "tertiary", "purchaseable", "yes"], ["Test 3", "options-outline", "false", "primary", "purchaseable", "no"], ["Concurs - IUNIE", "trophy-outline", "false", "tertiary", "purchaseable", "yes"]], id: 12 }
  ]; 

  public csf = "";
  public teacherCodes = ["9F", "GE", "XR", "9A", "OD", "8R", "KM", "EN", "IX", "WI", "47", "J0", "14", "U6", "O7", "L7", "ES", "OT", "5X", "8N", "PW", "5A", "UC", "UH", "U8", "72", "QU", "HP", "9Q", "EV", "RP", "LS", "JJ", "C1", "FN", "9C", "W8", "IB", "31", "RG", "37", "ED", "YS", "L9", "OU", "NO", "67", "TZ", "5K", "TS", "2M", "GY", "WT", "NV", "6M", "NK", "YO", "64", "4S", "OV", "QN", "QK", "B0", "P5", "M4", "EG", "93", "FA", "TX", "ID", "CN", "3E", "H1", "AL", "MI", "28", "19", "6K", "ND", "AN", "33", "GG", "FH", "VN", "8P", "GA", "6O", "G1", "TT", "XM", "99", "RY", "F5", "5F", "4G", "XA", "X4", "KN", "SY", "N7", "W1", "V2", "LO", "HZ", "VF", "LY", "OA", "5I", "PT", "5R", "8U", "SP", "1Y", "IV", "YJ", "39", "JT", "J3", "AC", "TM", "JE", "FX", "5O", "PA", "HC", "0J", "BF", "5U", "OZ", "5N", "MR", "ZU", "YE", "IW", "M2", "2N", "7Z", "3Y", "6N", "WA", "EZ", "AM", "0I", "IE", "CR", "05", "F1", "CA", "ZZ", "11", "A3", "Z5", "F8", "0B", "MK", "2W", "JC", "KD", "Q3", "16", "LN", "3W", "B7", "GZ", "C3", "7X", "MP", "UY", "XJ", "IT", "2Q", "E9", "AK", "5S", "UO", "ZD", "KC", "Y8", "FK", "RR", "X3", "KH", "73", "9J", "7M", "S3", "E2", "L5", "TU", "OM", "CW", "JX", "8V", "9D", "H9", "1W", "XD", "4I", "GW", "Y6"];
  public contestCodes = ["AM", "GE", "XR", "9A", "OD", "8R", "KM", "EN", "IX", "WI", "47", "J0", "14", "U6", "O7", "L7", "ES", "OT", "5X", "8N", "PW", "5A", "UC", "UH", "U8", "72", "QU", "HP", "9Q", "EV", "RP", "LS", "JJ", "C1", "FN", "9C", "W8", "IB", "31", "RG", "37", "ED", "YS", "L9", "OU", "NO", "67", "TZ", "5K", "TS", "2M", "GY", "WT", "NV", "6M", "NK", "YO", "64", "4S", "OV", "QN", "QK", "B0", "P5", "M4", "EG", "93", "FA", "TX", "ID", "CN", "3E", "H1", "AL", "MI", "28", "19", "6K", "ND", "AN", "33", "GG", "FH", "VN", "8P", "GA", "6O", "G1", "TT", "XM", "99", "RY", "F5", "5F", "4G", "XA", "X4", "KN", "SY", "N7", "W1", "V2", "LO", "HZ", "VF", "LY", "OA", "5I", "PT", "5R", "8U", "SP", "1Y", "IV", "YJ", "39", "JT", "J3", "AC", "TM", "JE", "FX", "5O", "PA", "HC", "0J", "BF", "5U", "OZ", "5N", "MR", "ZU", "YE", "IW", "M2", "2N", "7Z", "3Y", "6N", "WA", "EZ", "AM", "0I", "IE", "CR", "05", "F1", "CA", "ZZ", "11", "A3", "Z5", "F8", "0B", "MK", "2W", "JC", "KD", "Q3", "16", "LN", "3W", "B7", "GZ", "C3", "7X", "MP", "UY", "XJ", "IT", "2Q", "E9", "AK", "5S", "UO", "ZD", "KC", "Y8", "FK", "RR", "X3", "KH", "73", "9J", "7M", "S3", "E2", "L5", "TU", "OM", "CW", "JX", "8V", "9D", "H9", "1W", "XD", "4I", "GW", "Y6"];
  
  public dataClass = [{ id: 0, name: "stagelvl1" }, { id: 1, name: "stagelvl2" }, { id: 2, name: "stagelvl3" }, { id: 3, name: "stagelvl4" },
  { id: 4, name: "stagelvl5" }, { id: 5, name: "stagelvl6" }, { id: 6, name: "stagelvl7" }, { id: 7, name: "stagelvl8" }, { id: 8, name: "stagelvl9" },
  { id: 9, name: "stagelvl10" }, { id: 10, name: "stagelvl11" }, { id: 11, name: "stagelvl12" }];

  public dataLabels = [{ id: 0, name: "A" }, { id: 1, name: "B" }, { id: 2, name: "C" }, { id: 3, name: "D" }, { id: 4, name: "E" }, { id: 5, name: "F" }, { id: 6, name: "G" }, { id: 7, name: "H" }, { id: 8, name: "I" },
  { id: 9, name: "J" }, { id: 10, name: "K" }, { id: 11, name: "L" }, { id: 11, name: "M" }, { id: 12, name: "N" }, { id: 13, name: "O" }, { id: 14, name: "P" }, { id: 15, name: "Q" }, { id: 16, name: "R" },
  { id: 17, name: "S" }, { id: 18, name: "T" }, { id: 19, name: "U" }, { id: 20, name: "V" }, { id: 21, name: "W" }, { id: 22, name: "X" }, { id: 23, name: "Y" }, { id: 24, name: "Z" }];
}
