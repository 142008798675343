import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './auth/login/login.guard';
import { environment } from './../environments/environment';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    , canLoad: [LoginGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'exam',
    loadChildren: () => import('./exam/exam.module').then(m => m.ExamPageModule)
    , canLoad: [LoginGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./auth/tutorial/tutorial.module').then(m => m.TutorialPageModule)
  },
  {
    path: 'ar',
    loadChildren: () => import('./auth/ar/ar.module').then(m => m.ArPageModule)
  },
  {
    path: 'home/paymentconfirmation',
    loadChildren: () => import('./home/paymentconfirmation/paymentconfirmation.module').then(m => m.PaymentconfirmationPageModule)
    , canLoad: [LoginGuard]
  }
  /*,
  {
    path: 'coordinator',
    loadChildren: () => import('./home/coordinator/coordinator.module').then(m => m.CoordinatorPageModule)
    ,canLoad: [this.loginService.activeUser.coordinator.length === 7]
  }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
