import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-billspopup',
  templateUrl: './billspopup.component.html',
  styleUrls: ['./billspopup.component.scss'],
})

export class BillspopupComponent implements OnInit {
  @Input() billsObject;
  billsArray = [];
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    for (let i = 0; i < this.billsObject.length; i++) {
      this.billsArray.push({ startDate: new Date(this.billsObject[i].startDate).toLocaleDateString('ro-RO', { timeZone: 'EST' }), path: this.billsObject[i].path })
    }
  }
  dismissPopup() {
    this.modalCtrl.dismiss({});
  }
}
