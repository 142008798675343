import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { TranslatorService } from 'src/app/translator.service';
class Port {
  public id: number;
  public name: string;
}
@Component({
  selector: 'app-questionpopup',
  templateUrl: './questionpopup.component.html',
  styleUrls: ['./questionpopup.component.scss'],
})
export class QuestionpopupComponent implements OnInit {
  @Input() questionData;
  public acceptedChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  public questionTypePort: Port[];
  public questionTypeVal: Port;
  public questionTypeMaterie: Port[];
  public questionMaterieVal: Port;
  public questionText: string = "";
  public answerText: string = "";
  //public answers: any = [];
  public answerValue;
  public questionValue = "";
  constructor(private modalCtrl: ModalController, public translator: TranslatorService) { }

  ngOnInit() {
    this.questionTypePort = [{ id: 0, name: this.translator.lang['questionpopone'] }, { id: 1, name: this.translator.lang['questionpopmultiple'] }];
    this.questionTypeMaterie = [{ id: 0, name: this.translator.lang['questionpopro'] }, { id: 1, name: this.translator.lang['questionpopmath'] }, { id: 2, name: this.translator.lang['questionpopgeo'] },
    { id: 3, name: this.translator.lang['questionpopknow'] }, { id: 4, name: this.translator.lang['questionpopothers']}];
    //console.log(this.questionData);
    if (this.questionData.questionType && this.questionData.questionType.length != 0) {

      this.questionTypeVal = this.questionTypePort[this.questionData.questionType - 1];
      this.questionText = this.questionData.question;
      this.questionMaterieVal = this.questionTypeMaterie[this.questionData.questionMaterie];
      this.questionValue = this.questionData.points;
    }
  }

  questionTypeChanged(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    if (this.questionTypeVal) {
      this.questionData.questionType = "" + (+this.questionTypeVal.id + 1);
    }
  }

  questionMaterieChanged(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    if (this.questionMaterieVal) {
      this.questionData.questionMaterie = "" + (+this.questionMaterieVal.id + 1);
    }
  }

  public setAnswerValue(ev: any) {
    this.answerValue = ev.detail.value;
  }
  public updateAnswerLabel(ev: any, idx) {
    this.questionData.answers[idx].label = ev.detail.value;
  }
  public updateAnswerSelection(ev: any, idx) {
    this.questionData.answers[idx].val = ev.detail.value;
  }

  addAnswer() {
    if (this.answerText.length > 0 && this.answerValue != undefined) {
      this.questionData.answers.push({ label: this.answerText, val: this.answerValue });
      this.answerText = "";
    }
    else {
      alert(this.translator.lang['questionpopalert']);
    }
  }
  removeAnswer(idx) {
    this.questionData.answers.splice(idx, 1);
  }

  checkPoints(ev: any) {
    if (this.acceptedChars.indexOf(ev.key) === -1) {
      this.questionValue = this.questionValue.slice(0, this.questionValue.length - 1);
    }

  }
  dismissPopup(action) {
    let countRightAnswers = 0;
    for (let i = 0; i < this.questionData.answers.length; i++) {
      if (this.questionData.answers[i].val === "true") {
        countRightAnswers++;
      }
    }
    if (action === 'save') {
      if (this.questionText.length === 0) {
        alert(this.translator.lang['questionpopmissq']);
        return;
      }
      else if (this.questionData.answers.length < 2) {
        alert(this.translator.lang['questionpopmissa']);
        return;
      }
      else if (this.questionMaterieVal === undefined) {
        alert(this.translator.lang['questionpoptype']);
        return;
      }
      else if (this.questionValue.length === 0) {
        alert(this.translator.lang['questionpoppoints']);
        return;
      }
      else if (this.questionData.questionType === "1") {
        if (countRightAnswers != 1) {
          alert(this.translator.lang['questionpopqtype'])
          return;
        }
      }
      else if (this.questionData.questionType === "2") {
        let count = +this.questionValue % this.questionData.answers.length;
        if (count != 0) {
          alert(this.translator.lang['questionpoptypemulti']);
          return;
        }
      }
      if (countRightAnswers === 0) {
        alert(this.translator.lang['questionpopminanswer']);
        return;
      }
      this.questionData.question = this.questionText;
      this.questionData.questionMaterie = this.questionMaterieVal.id;
      this.questionData.points = this.questionValue;
    }
    this.modalCtrl.dismiss({ action: action, questionData: this.questionData });
  }
}
